import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import { LoadingButton } from '@material-ui/lab'
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core'
// routes
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useEffect, useState } from 'react'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/DefaultValues'
import useAuth from 'src/hooks/useAuth'
import { handleGoBack } from 'src/utils/InputFunction'

// ----------------------------------------------------------------------

export default function AddVentor() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const location = useLocation()
  const vendor_id = location.state?.vendor_id
  const isEdit = location.state?.isEdit
  const { enqueueSnackbar } = useSnackbar()
  const [sameAs, setSameAs] = useState('')
  const [toggleButton, setToggleButton] = useState(false)
  const [ranks, setRanks] = useState([])
  const [partnerType, setPartnerType] = useState([])
  const [customerType, setCustomerType] = useState([])
  const [customerRefferals, setCustomerRefferals] = useState([])
  const [states, setStates] = useState([])
  const [taxCategories, setTaxCategory] = useState([])
  const [editedData, setEditedData] = useState({})

  const getDataToaddVendors = () => {
    axios
      .post(`${REST_API_END_POINT}wholesale/get-data-for-add-vendor`, {
        facility_id: user.facility_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setRanks(res.data.ranks)
          setPartnerType(res.data.partner_types)
          setCustomerType(res.data.customer_types)
          setCustomerRefferals(res.data.customer_referrals)
          setStates(res.data.states)
          setTaxCategory(res.data.tax_categories)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getEditedDataofVendor = () => {
    axios
      .post(`${REST_API_END_POINT}wholesale/get-vendor-by-id`, {
        vendor_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setEditedData(res.data.vendor)
        }
      })
      .catch((err) => {
        console.log(err, 'error ')
      })
  }
  useEffect(() => {
    getEditedDataofVendor()
  }, [vendor_id])
  useEffect(() => {
    getDataToaddVendors()
  }, [user])

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    zipcode: Yup.string().required('Zipcode is required'),
    city: Yup.string().required('City is required'),
    ventor_type: Yup.object().required('Select a Type').nullable(),
    phoneNumber: Yup.string()
      .matches(
        /^(\+?\d{1,3}[- ]?)?\d{10}$/,
        'Phone number is not valid. It should be 10 digits',
      )
      .required('Phone number is required'),
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
    trade: Yup.string().required('Trade is required'),
    state: Yup.object().required('State is required').nullable(),
  })

  const initialState =
    isEdit && states.find((d) => d.id === editedData?.state_id)
  const initialPartnerType =
    isEdit && partnerType.find((d) => d.id === editedData?.partner_type)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editedData?.vendor_name || '',
      ventor_type: initialPartnerType || null,
      address: editedData?.vendor_address || '',
      state: initialState || null,
      zipcode: editedData?.zip_code || '',
      trade: editedData?.trade_name || '',
      phoneNumber: editedData?.phone || '',
      license:editedData?.license||'',
      role: editedData?.vendor_role || '',
      email: editedData?.email_id || '',
      city: editedData?.vendor_city || '',
      same_as_vendor_name: editedData?.same_as_vendor_name === 1 ? true : false,
    },
    validationSchema: NewUserSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const formattedValues = {
        trade_name: values.trade,
        customer_name: values.name,
        customer_address: values.address,
        vendor_city: values.city,
        partner_type: values.ventor_type['id'],
        state_id: values.state['id'],
        vendor_role: values.role || '',
        same_as_vendor_name: values.same_as_vendor_name === true ? 1 : 0,
        phone: values.phoneNumber,
        license:values.license,
        email: values.email,
        zip_code: values.zipcode,
        omma_verified: 1,
        recommended_plants: 1,
        smokable_quantity: 1,
        customer_refer_from: 1,
        state_id_license: 1,
        state_id_exp_license: 1,
        tax: 1,
        rank_id: 1,
      }
      axios
        .post(
          `${REST_API_END_POINT}wholesale/${isEdit ? 'edit' : 'add'}-vendor`,
          {
            facility_id: user.facility_id,
            user_id: user.id,
            vendorId: editedData.vendor_id,
            values: formattedValues,
          },
        )
        .then((res) => {
          if (res.data.status === 1) {
            setSubmitting(false)
            enqueueSnackbar(res.data.message, { variant: 'success' })
            handleGoBack()
            resetForm()
          } else {
            enqueueSnackbar(res.data.message, { variant: 'error' })
            setSubmitting(false)
          }
        })
        .catch((err) => {
          console.log(err, 'error in adding vendor')
        })
    },
  })

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Typography variant="h4">
                  {isEdit ? 'Edit' : 'Add'} New Vendor
                </Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Vendor Name"
                    {...getFieldProps('name')}
                    onChange={(e) => {
                      const { value } = e.target
                      setFieldValue('name', value)
                      setSameAs(value)
                    }}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Stack>
                <FormControlLabel
                  control={
                    <Switch
                      checked={toggleButton}
                      onChange={(e) => {
                        setToggleButton(e.target.checked)
                        setFieldValue('same_as_vendor_name', e.target.checked)
                        if (e.target.checked) {
                          setFieldValue('trade', sameAs)
                        } else {
                          setFieldValue('trade', '')
                        }
                      }}
                    />
                  }
                  label="Same as Vendor Name"
                />
                <TextField
                  fullWidth
                  label="Trade Name"
                  {...getFieldProps('trade')}
                  value={toggleButton ? sameAs : getFieldProps('trade').value}
                  onChange={(e) => {
                    const { value } = e.target
                    setFieldValue('trade', value)
                  }}
                  error={Boolean(touched.trade && errors.trade)}
                  helperText={touched.trade && errors.trade}
                />

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Vendor Address"
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                  <TextField
                    fullWidth
                    label="Vendor City"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    options={states}
                    getOptionLabel={(option) => option.name}
                    value={values.state}
                    onChange={(event, newValue) => {
                      setFieldValue('state', newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select States"
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    )}
                  />
                  <TextField
                    fullWidth
                    label="Zip/Code"
                    {...getFieldProps('zipcode')}
                    error={Boolean(touched.zipcode && errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    {...getFieldProps('phoneNumber')}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                  <TextField
                    fullWidth
                    label="Licence"
                    {...getFieldProps('license')}
                    error={Boolean(touched.license && errors.license)}
                    helperText={touched.license && errors.license}
                  />
                  <TextField
                    fullWidth
                    label="Email Address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    options={partnerType}
                    getOptionLabel={(option) => option.name}
                    value={values.ventor_type}
                    onChange={(event, newValue) => {
                      setFieldValue('ventor_type', newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Type"
                        error={Boolean(
                          touched.ventor_type && errors.ventor_type,
                        )}
                        helperText={touched.ventor_type && errors.ventor_type}
                      />
                    )}
                  />

                  <TextField
                    fullWidth
                    label="Role"
                    {...getFieldProps('role')}
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                  />
                </Stack>

                <Box
                  sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button variant="outlined" sx={{ mr: 1 }}>
                    <Link
                      to={PATH_DASHBOARD.vendor.AllVentors}
                      style={{ textDecoration: 'none' }}
                    >
                      Cancel
                    </Link>
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {!isEdit ? 'Add' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
