import React from 'react';
import { useSnackbar } from 'notistack';
import {
  Stack,
  Button,
  Card,
  Alert,
  AlertTitle,
  Paper,
} from '@material-ui/core';
import Scrollbar from 'src/components/Scrollbar';
import { LoadingButton } from '@material-ui/lab';

const notifications = [
  {
    id: 1,
    title: '  product category Concentrate  has been edited.',
    content: 'John Doe.',
    variant: 'info',
    Date:'09/27/2024',
  },
  {
    id: 2,
    title: 'The staff asdf has been deleted.',
    content: 'John Doe.',
    variant: 'error',
    Date:'09/27/2024',
  },
  {
    id: 3,
    title: 'The vendor type high priorType has been deleted.',
    content: 'John Doe',
    variant: 'error',
    Date:'09/27/2024',
  },
  {
    id: 4,
    title: 'The vendor type high priorType has been made active.',
    content: 'John Doe',
    variant: 'success',
    Date:'09/27/2024',
  },
];

// ----------------------------------------------------------------------

export default function NotificationInsidePOS({ setOpenNotification }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleDismissAll = () => {
    setOpenNotification(false);
  };

  return (
    <Card variant='outlined' sx={{boxShadow: 'none',p:2 }}>
      <Stack spacing={2}>
        <Scrollbar sx={{ maxHeight: 500 }}>
          <Stack spacing={2}>
            {notifications.map((notification) => (
              <Alert
                key={notification.id}
                variant="outlined"
                severity={notification.variant}
              >
                <AlertTitle>{notification.title}</AlertTitle>
                Done By {notification.content} On {notification.Date}
              </Alert>
            ))}
          </Stack>
        </Scrollbar>

        <Stack direction="row" justifyContent="flex-start" sx={{ mt: 3 }}>
          <LoadingButton
            variant="contained"
            onClick={() => {enqueueSnackbar('Notification Marked As Read', { variant: 'success' });setOpenNotification(false);}}
          >
            Mark as read
          </LoadingButton>

          <Button
            type="button"
            color="error"
            variant="outlined"
            onClick={handleDismissAll}
            sx={{ ml: 1.5 }}
          >
            Close
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
