export const GOOGLE_API_KEY = "AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg";

// export const REST_API_END_POINT = "http://localhost:5000/webservice/";
// export const REST_API = "http://localhost:5000/";
// export const BASE_URL = "http://localhost:3000/";

// export const REST_API_END_POINT = "http://batman-api.skyniche.website/webservice/";
// export const REST_API = "http://batman-api.skyniche.website/"; 
// export const BASE_URL = "http://batman.skyniche.website/";

export const REST_API_END_POINT = "https://bagempirela.com/backend/webservice/";
export const REST_API = "https://bagempirela.com/backend/";
export const BASE_URL = "https://bagempirela.com/";

export const LBS_CONSTANT= 453.59237;
export const Build = '1.38';
export const Features = [
  // { id: 1, label: 'Customer Signup' },
  // { id: 2, label: 'Shop product enquiry' },
  // { id: 3, label: 'QR code in Contact Us Page' },
  // { id: 4, label: 'Void Order Handling' },
  // { id: 5, label: 'Full and partial payment, and show due in invoice and receipt' },
  // { id: 6, label: 'Only allow the receipt to be printed after payment is completed' },
  // { id: 7, label: 'Total quantity on the invoice' },
  // { id: 8, label: 'Remove the phone number from the receipt if not added' },
  { id: 1, label: 'Individual checkout' },
  { id: 2, label: 'New Filters on Order.' },
  { id: 3, label: 'Zelle and Crypto payment.' },
]
export const BugFixes = [
  // { id: 1, label: 'Scroll issue in enquiry' },
  // { id: 2, label: 'Restricted showing product with zero price in shop and placing order with a zero price' },
  // { id: 3, label: 'Daily Limit Issue' },
  // { id: 4, label: 'POS UI design break' },
  { id: 1, label: 'Removed Driver licence upload in customer signup' },
  { id: 2, label: 'Updated customer register page' },
  { id: 3, label: 'Mobile-->Bag empire->Which displays 404 error' },
  { id: 4, label: 'Order can be completed after full payment' },
  { id: 5, label: 'Decimal issue fix make payment' },
  { id: 6, label: 'partial payment amount calculation issue' },
]
export const OUNCE_CONSTANT = 28;
export const FACILITY_ID = 7;
export const INVENTORY_SOURCES = { '0': 'Inventory', '1': 'Grow House', '2': 'Vendor', '3': 'Extractor', '4': 'Rotovap', '5': 'Distillator', '6': 'Kitchen', '7': 'Oven', '8': 'MCP' };


