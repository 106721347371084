
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { Box, Grid, Step, Stepper, Container, StepLabel, StepConnector, Card, CardHeader, Typography, Avatar, CardContent, Button, Stack, Divider, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Badge, Autocomplete, TextField, CircularProgress, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// redux
import { useSelector } from '../../../../../redux/store';
import { getCart, createBilling } from '../../../../../redux/slices/product';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// components
import Page from '../../../../../components/Page';
import ShopCard from './ShopCard';
import { useState } from 'react';
import { AccountCircle, ArrowBack, ArrowBackIos, Category, CategoryOutlined, FilterVintage, FollowTheSignsSharp, HandymanSharp, LocalOffer, LocalOfferOutlined, Mail, NotificationsActive, Person, Reply, Spa, TravelExplore } from '@material-ui/icons';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import useAuth from 'src/hooks/useAuth';
import moment from 'moment';
import Label from 'src/components/Label';
import { MBadge, MIconButton } from 'src/components/@material-extend';
import BGGIf from '../../../../../logo/batman-bg.gif'
import CheckoutConfirmation from 'src/components/_dashboard/pop-ups/CheckoutConfirmation';
import Modal from '../../ProjectModel';
import { useSnackbar } from 'notistack';
import NotificationInsidePOS from '../../NotificationInsidePOS';
import NewShopCard from './NewShopCard';
import NewShopCardForEdit from './NewShopCardForEdit';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import Logo from '../../../../../logo/image.png'

// ----------------------------------------------------------------------

const STEPS = ['Cart', 'Billing & address', 'Payment'];

const customer = [
    {
        pay: '0.00',
        change: '0',
        points: '3285'
    }
]
const QontoConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 20px)',
        right: 'calc(50% + 20px)'
    },
    active: {
        '& $line': { borderColor: theme.palette.primary.main }
    },
    completed: {
        '& $line': { borderColor: theme.palette.primary.main }
    },
    line: {
        borderTopWidth: 2,
        borderColor: theme.palette.divider
    }
}))(StepConnector);

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool
};

function QontoStepIcon({ active, completed }) {

    return (
        <Box
            sx={{
                zIndex: 9,
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: active ? 'primary.main' : 'divider',
                bgcolor: 'background.default'
            }}
        >
            {completed ? (
                <Box component={Icon} icon={checkmarkFill} sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }} />
            ) : (
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: 'currentColor'
                    }}
                />
            )}
        </Box>
    );
}

export default function CheckIn() {

    const navigate=useNavigate()
    const {user} = useAuth()
    const location = useLocation()
    const customer_id = location?.state?.customer_id
    const facility_id =  user.facility_id
    const isPickUp = location?.state?.placeOrder
    const readyToPlaceOrder = location?.state?.ready_to_order_sale
    const orderId = location?.state?.orderId
    const { checkout } = useSelector((state) => state.product);
    const [customerData,setCustomerData] = useState({})
    const [orders,setOrders] = useState([])
    const [couponInfos,setCouponInfo] = useState([])
    const [deals,setDeals] = useState([])
    const [mixDeals,setMixDeals] = useState([])
    const [currencies,setCurrencies] = useState([])
    const [categories,setCategories] = useState([])
    const [products,setProducts] = useState([])
    const [fullscreen, setFullscreen] = useState(false);
    const [loadingproducts, setLoadingproducts] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [openCustomerLeft, setOpenCustomerLeft] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [selectedReason, setSelectedReason] = useState(null);
    const [otherReason, setOtherReason] = useState('');
    const [selectedReasonError, setSelectedReasonError] = useState('');
    const [selectedReasonTouched, setSelectedReasonTouched] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const customerLeftReasons = [
        { label: customerData?.vendor_name+" didn’t have cash", value: "1", key: 0 },
        { label: customerData?.vendor_name+" was unhappy with pricing", value: "2", key: 1 },
        { label: customerData?.vendor_name+" didn’t like the customer experience", value: "3", key: 2 },
        { label: customerData?.vendor_name+" was part of a group", value: "4", key: 3 },
        { label: "I don’t know", value: "5", key: 4 },
        { label: "Other", value: "6", key: 5 }
    ]

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
        }
    };
const getUserData=()=>{
    axios.post(`${REST_API_END_POINT}register/get-user-data`,{})
}
const getDataForAddVendors=()=>{
    try {
        axios.post(`${REST_API_END_POINT}wholesale/get-data-for-add-vendor`,{
            facility_id:user.facility_id
        })
        .then((res)=>{
            if(res.data.status===1){

            }
        })
        .catch((err)=>{
            console.log(err)
        })
    } catch (error) {
        console.log(error)
    }
}

const getDataForPOS=async()=>{
    setLoadingproducts(true)
    try {
        await axios.post(`${REST_API_END_POINT}point-of-sale/pos`,{
            facility_id:user.facility_id,
            user_id:user.id,
            customer_id,
            user_time:moment().format('HH:mm:00')
        })
        .then((res)=>{
            console.log("dataaaaaaaaaaaaaaaaaa",res.data)
            if(res.data.status===1){
                setCustomerData(res.data.customer)
                setCurrencies(res.data.currencies)
                setCouponInfo(res.data.couponInfos)
                setCategories(res.data.categories)
                setProducts(res.data.products)
                setDeals(res.data.deals)
                setOrders(res.data.orders)
                setMixDeals(res.data.mixDeals)
            }
        })
        .catch((err)=>{
            console.log(err)
            setProducts([])
        })
    }catch(error){
        console.log(error)
    }finally{
        setLoadingproducts(false)
    }
}
useEffect(()=>{
    getDataForAddVendors()
    getDataForPOS()
},[])

const handleCheckoutConfirm = () => {
    axios.post(`${REST_API_END_POINT}customer/customer-left`, {
        customer_id, facility_id:user.facility_id, reason : selectedReason?.label, other_reason:otherReason, user_id:user.id,
        }).then((res)=>{
            navigate(PATH_DASHBOARD.pov.CustomerLookup)
        })
        .catch(e => {
            console.log("Checkout failed, Error: ", e);
            enqueueSnackbar("Checkout failed", { variant: 'error' });
        });
    setConfirmDialogOpen(false);
};

const handleReasonChange = (event, newValue) => {
    setSelectedReason(newValue);
    if (!newValue) {
      setSelectedReasonError('Please select a reason');
    } else {
      setSelectedReasonError('');
    }
  };
  
  const handleBlur = () => {
    setSelectedReasonTouched(true);
    if (!selectedReason) {
      setSelectedReasonError('Please select a reason');
    }
  };

  const handleClose = () => {
    setOpenCustomerLeft(false); 
    setSelectedReason(null); 
    setSelectedReasonError(false); 
    setSelectedReasonTouched(false);
    setOpenNotification(false)
  }
  

    return (
        <Page title={user?.facility_name || 'Get Go'}>
            <Container sx={{ mt: -8,mb:-9}}>
            <Accordion variant='outlined'>
                    <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}>
                    <Box display="flex" alignItems="center">
                            <Avatar
                              src={(user?.profile_pic && user?.profile_pic !== "default1.jpg") ? user?.profile_pic : Logo}
                            //   src={customerData?.profile_pic}
                              alt={user.first_name}
                              sx={{ height: '50px', width: '50px', }}
                            />
                            <Box ml={2}>
                              <Box display="flex" flexDirection={'column'} alignItems="center">
                              {/* <Badge badgeContent={7} color="primary"> */}
                                <Typography variant='h6'>
                                  {user.first_name} {user.last_name}
                                </Typography>
                                {/* </Badge> */}
                              </Box>
                            </Box>
                          </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                          {/* <Box display="flex" alignItems="center">
                            <Avatar
                              src={(user?.profile_pic && user?.profile_pic !== "default1.jpg") ? user?.profile_pic : Logo}
                            //   src={customerData?.profile_pic}
                              alt={user.first_name}
                              sx={{ height: '100px', width: '100px',border:'1px solid #000' }}
                            />
                            <Box ml={2}>
                              <Box display="flex" flexDirection={'column'} alignItems="center">
                                <Typography variant='h6'>
                                  {user.first_name} {user.last_name}
                                </Typography>
                              </Box>
                            </Box>
                          </Box> */}
                        </Grid>
                        <Grid item xs={12} md={6} sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            alignContent:'center',
                            justifyContent:'center'
                        }} >
                                <Grid item>
                                    {customerData?.vendor_name ?
                                    <Button variant="contained" sx={{ backgroundColor: '#6C63FF', mr:2,mb:{xs:2,sm:0,md:0}, cursor:'default', '&:hover': { backgroundColor: '#6C63FF' } }} startIcon={<AccountCircle />}>
                                        <Typography variant="subtitle2" component="span">
                                            Checked In As {customerData?.vendor_name}
                                        </Typography>
                                    </Button>
                                    : ''
                                    // <Label color="info" sx={{padding:'21px 15px',}}>Checked In As {customerData?.vendor_name}</Label>
                                    }
                                    {/* <Typography variant="h6">
                                        Current Payment: ${data.pay}
                                    </Typography> */}
                                    {/* <Typography variant="h6">Change: ${data.change}</Typography> */}
                                    <Label color="success" sx={{padding:'21px 15px',}}>Current Customer Points: ${customerData?.price_points}</Label>
                                    {/* <Typography variant="h6">
                                        Current Customer Points: ${customerData?.price_points}
                                        </Typography> */}
                                </Grid>
                            {/* <Grid item>
                                <Button
                                    variant='contained'
                                    component={RouterLink}
                                    to={PATH_DASHBOARD.pov.CustomerLookup}
                                    onClick={toggleFullScreen}
                                >back</Button>
                            </Grid> */}
                        </Grid>
                        <Grid item xs={12} md={12} sx={{
                            display: "flex",
                            flexDirection: {xs:'column',sm:'column',md:'row'},
                            alignContent: {xs:'center', sm:'center', md:'flex-end'},
                            justifyContent: {xs:'center', sm:'center', md:'flex-end'},
                            mt: {xs:2, sm:2, md:1.5}
                        }}>
                            <Grid item sx={{
                                display: 'flex',
                                flexDirection: {xs:'column',sm:'row',md:'row'},
                                justifyContent: 'space-between',
                                mr: 1
                            }}>
                                <MIconButton sx={{ backgroundColor:'rgba(110, 97, 84, 0.16)',height:'45px',width:'45px',
                                    color: 'primary.main',
                                    '&:hover': {
                                    backgroundColor:'rgba(110, 97, 84, 0.8)',
                                    },mb:{xs:2,sm:2,md:0}}} 
                                    // onClick={()=> window.history.back() }
                                    component={RouterLink}
                                    to={PATH_DASHBOARD.pov.CustomerLookup}
                                    >
                                    <ArrowBack sx={{fontSize:'25px', fontWeight:'bold'}}/>
                                </MIconButton>
                                <Button
                                    variant="contained"
                                    sx={{
                                       mx: 1, 
                                       mb:{xs:1,sm:0,md:0},
                                       ml:{xs:1,sm:1,md:2},
                                    }}
                                    onClick={()=>{ navigate(PATH_DASHBOARD.adding.AddeditCustomer, { state: { vendor_id: customer_id } }) }}
                                    startIcon={<Person />}
                                >
                                    Edit Profile
                                </Button>
                                <Button
                                    variant='contained'
                                    color='warning'
                                    component={RouterLink}
                                    to={PATH_DASHBOARD.pov.AllOrders}
                                    // onClick={toggleFullScreen}
                                    sx={{
                                        mx: 1, 
                                        mb:{xs:1,sm:0,md:0},
                                        color: '#000',
                                        borderColor: '#FFA500',
                                    }}
                                    startIcon={<Reply />}
                                >
                                    Refund Purchase
                                </Button>
                                <Button
                                    variant='contained'
                                    color='success'
                                    component={RouterLink}
                                    to={PATH_DASHBOARD.pov.orderAhead}
                                    // onClick={toggleFullScreen}
                                    sx={{
                                        mx: 1, 
                                        mb:{xs:1,sm:0,md:0},
                                    }}
                                    startIcon={<Stack className='carbon--return' sx={{fontSize:'1.5rem'}} />}
                                >
                                    Return To Queue
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={()=>{setOpenCustomerLeft(true);}}
                                    color='error'
                                    sx={{
                                        mx: 1, 
                                        mb:{xs:1,sm:0,md:0},
                                    }}
                                    startIcon={<Stack className='mdi--user-alert-outline' sx={{fontSize:'1.5rem'}} />}
                                >
                                    Customer Left
                                </Button>
                                <Modal open={openCustomerLeft} handleClose={handleClose} onClose={handleClose}
                                    modalTitle={'Customer Left'}  >
                                    <Divider />
                                    <Box sx={{ display:'flex', flexDirection:'column', gap:2, justifyContent:'space-evenly' }}>
                                        <Autocomplete
                                            fullWidth
                                            options={customerLeftReasons || []}
                                            getOptionLabel={(option) => (option ? option?.label : '')}
                                            value={selectedReason || null}
                                            onChange={handleReasonChange}
                                            onBlur={handleBlur}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    placeholder="Select Reason"
                                                    label="Select the reason why:"
                                                    error={Boolean(selectedReasonTouched && selectedReasonError)}
                                                    helperText={selectedReasonTouched && selectedReasonError}
                                                />
                                            )}
                                        />
                                        {selectedReason?.label == 'Other' && <TextField
                                            type="text"
                                            placeholder="Enter the Reason"
                                            label="Other Reason"
                                            onChange={(event, value)=>{
                                                const newValue = event.target.value
                                                setOtherReason(newValue)
                                            }}
                                            error={Boolean(selectedReasonTouched && selectedReasonError)}
                                            helperText={selectedReasonTouched && selectedReasonError}
                                        />}
                                    </Box>

                                    <Divider sx={{ my: 3 }} />
                                    <Box sx={{ display: 'flex', justifyContent: 'center', gap:1 }}>
                                        <Button onClick={handleClose} variant='outlined' color='error'>Cancel</Button>
                                        <Button onClick={()=>{
                                            setSelectedReasonTouched(true);
                                            if (!selectedReason) {
                                                setSelectedReasonError('Please select a reason');
                                                return;
                                            }else{
                                                // setConfirmDialogOpen(true); 
                                                setOpenCustomerLeft(false); 
                                                handleCheckoutConfirm()
                                            }
                                        }} variant='contained'>Submit</Button>
                                    </Box>
                                </Modal>
                                <CheckoutConfirmation
                                    open={confirmDialogOpen}
                                    onClose={()=>{setConfirmDialogOpen(false)}}
                                    onConfirm={handleCheckoutConfirm}
                                    title="Are you sure?"
                                    message="Do you want to proceed with the checkout?"
                                    confirmText="Proceed"
                                    cancelText="Cancel"
                                />
                                {/* <Button
                                    variant='outlined'
                                    color='warning'
                                    component={RouterLink}
                                    to={PATH_DASHBOARD.pov.CustomerLookup}
                                    onClick={toggleFullScreen}
                                    sx={{
                                        mx: 1,
                                        mb:{xs:1.5,sm:0,md:0},
                                        '&:hover': {
                                          backgroundColor: 'warning.main',
                                          color:'black'
                                        }
                                    }}
                                    startIcon={<ArrowBack />}
                                >
                                    Back
                                </Button> */}
                                <Badge badgeContent="7+" color="info" overlap="circular" 
                                       sx={{ 
                                           '.css-k6y4ub-MuiBadge-badge': { 
                                               height: 'fit-content', 
                                               width: 'fit-content', 
                                               padding: '6px 6px', 
                                               top: '10%', 
                                               right: {xs:'3%',sm:'6%',md:'6%'},
                                               borderRadius:'50%',
                                               display:'flex',
                                               justifyContent:'center',
                                               alignContent:'center'
                                           }
                                       }}>
                                    <Button
                                        variant='outlined'
                                        color='info'
                                        fullWidth
                                        onClick={()=>setOpenNotification(true)}
                                        sx={{
                                            mx: 1,
                                            position: 'relative',
                                        }}
                                        startIcon={<NotificationsActive />}
                                    >
                                        Notifications
                                    </Button>
                                </Badge>
                                <Modal open={openNotification} handleClose={handleClose} onClose={handleClose}
                                    modalTitle={'Notifications'}  >
                                        <NotificationInsidePOS setOpenNotification={setOpenNotification}/>
                                </Modal>
                            </Grid>
                        </Grid>
                    </Grid>
                    </AccordionDetails>
                    </Accordion>
                <Card container variant='outlined' spacing={1} sx={{ 
                    boxShadow:'none',
                    // backgroundImage: `url(${BGGIf})`, backgroundSize:'cover',backgroundPosition:'center',
                     mt:3 ,pb:2}} >
                        {/* <NewShopCard 
                        customerData={customerData}
                        products={products}
                        loadingproducts = {loadingproducts}
                        setLoadingproducts = {setLoadingproducts}
                        customer_id={customer_id}
                        orders={orders}
                        deals={deals}
                        categories={categories}
                        isPickUp={isPickUp}
                        order_id={orderId}
                        readyToPlaceOrder={readyToPlaceOrder}
                        />          */}

<NewShopCardForEdit 
                        customerData={customerData}
                        products={products}
                        loadingproducts = {loadingproducts}
                        setLoadingproducts = {setLoadingproducts}
                        customer_id={customer_id}
                        facility_id={facility_id}
                        orders={orders}
                        deals={deals}
                        categories={categories}
                        isPickUp={isPickUp}
                        order_id={orderId}
                        readyToPlaceOrder={readyToPlaceOrder}
                        />  
                   
             </Card>
            </Container>
        </Page>
    );
}
