import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
//
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isLogin = pathname === '/bagempire-login';
  const isReceiveInventory = pathname === '/receive-inventory-qr-code/:OrderID';
  const isAddOrgnization = pathname === '/add-organization';
  const isFacilityMain = pathname === '/facility-listing';
  const isLiveTracking = pathname === '/live-inventory-view';

  return (
    <>
      {!isLogin && !isAddOrgnization && !isFacilityMain && !isLiveTracking &&(<MainNavbar />)}
      <div>
        <Outlet />
      </div>

      {isLogin ? (
        ''
      ) : isAddOrgnization ? (
        ''
       ) : isFacilityMain ? (
        ''
       )  : isLiveTracking ? '' : <MainFooter />}
    </>
  );
}
