import { useRef, useState, useEffect } from 'react';
// material
import { alpha } from '@material-ui/core/styles';
import { Box, Grid, Stack, Typography } from '@material-ui/core';
import { MIconButton } from '../../components/@material-extend';
import { Home } from '@material-ui/icons';
import MenuPopover from '../../components/MenuPopover';
import { useSnackbar } from 'notistack';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

// Static array for country flags and names with category_id
const countryData = [
  // { label: 'Grove Dispensary', value: 'en', category_id: 1 },
  // { label: 'Monkey Island', value: 'de', category_id: 2 },
  { label: 'Get Go', value: 'fr', category_id: 3 },
  // { label: 'Facility 1', value: 'es', category_id: 4 }
];

export default function FacilityPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { onChangeLang } = useLocales();
  const [selectedCategory_id, setSelectedCategory_id] = useState(3); // Default selection for Batman Distribution
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSelectedCategory_id(3); // Set default selection to Batman Distribution
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (value) => {
    onChangeLang(value);
    handleClose();
  };

  const handleCategoryFilter = (category_id) => {
    if (category_id !== 3) {
      enqueueSnackbar('Coming Soon', { variant: 'error' }); // Display error for categories other than 3
    } else {
      setSelectedCategory_id(category_id); // Select the category
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Home sx={{ color: 'primary.main' }} />
      </MIconButton>

      <MenuPopover 
        open={open} 
        onClose={handleClose} 
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          // horizontal: 350,
          horizontal: 'right',
        }}
        sx={{ width: 'fit-content' }}
      >
        <Grid container spacing={2} sx={{ width: 'fit-content', padding: '16px' }}>
          {countryData.map((data, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  justifyContent: 'center',
                  width:{ xs: '100%', sm: '100%', md: '160px' },
                  cursor: 'pointer',
                  borderRadius: 0.8,
                  background:
                    selectedCategory_id === data.category_id
                      ? 'linear-gradient(to left, #000,#000,#000,#000)'
                      : 'common.white',
                  color:
                    selectedCategory_id === data.category_id
                      ? 'common.white'
                      : 'black',
                  '&:hover': {
                    background:
                      'linear-gradient(to left, #000,#000,#000,#000)',
                    color: 'white',
                  },
                }}
                onClick={() => handleCategoryFilter(data.category_id)}
              >
                <Home sx={{ fontSize: '1.5rem', mb: 0.6 }} />
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: 'center' }}
                >
                  {data.label}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </MenuPopover>
    </>
  );
}