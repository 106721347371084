export const handleNumberOnlyInput = (e) => {
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'Tab'
    ) {
      e.preventDefault();
    }
  };
  export const handleDecimalNumberOnlyInput = (e) => {
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== '.' && 
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'Tab'
    ) {
      e.preventDefault();
    }
  };
  
 export const handleGoBack = () => {
    window.history.back();
  };