import moment from 'moment'

export const empty = (data) => {
  if (!data) {
    return true
  }
  if (data === '0') {
    return true
  }
  if (typeof data === 'number' || typeof data === 'boolean') {
    return false
  }
  if (typeof data === 'undefined' || data === null) {
    return true
  }
  if (data == 'undefined' || data == null) {
    return true
  }
  if (typeof data.length !== 'undefined') {
    return data.length === 0
  }
  var count = 0
  for (var i in data) {
    if (data.hasOwnProperty(i)) {
      count++
    }
  }
  return count === 0
}
export const zeroPad = (input, length) => {
  return (Array(length + 1).join('0') + input).slice(-length)
}

export const formatDurationFromNow = (unixTimestamp) => {
  const now = moment()
  const timestampMoment = moment.unix(unixTimestamp)
  const duration = moment.duration(now.diff(timestampMoment))
  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  if (days > 0) {
    return `${days} Day`
  }
  let result = ''
  if (hours > 0) {
    result += `${hours} Hr `
  }
  if (minutes > 0) {
    result += `${minutes} m `
  }
  if (seconds > 0) {
    result += `${seconds} s `
  }
  return result.trim()
}
export const getStatusColorAndLabel = (status) => {
  switch (status) {
    case 0:
      return { color: 'error', label: 'Inactive' }
    case 1:
      return { color: 'success', label: 'Active' }
    default:
      return { color: 'error', label: 'Inactive' }
  }
}
export const getStatusOmmaVerify = (status) => {
  switch (status) {
    case 0:
      return { color: 'error', label: 'In Active' }
    case 1:
      return { color: 'success', label: 'Active' }
    case 2:
      return { color: 'error', label: 'Inactive' }
  }
}

export const returnDataBypassingIds = (source, ids) => {
  if (!ids) return []
  return ids
    .split(',')
    .map((id) => source.find((item) => item.id == id))
    .filter((item) => item !== undefined)
}
