import React, { useEffect, useState } from 'react';
import { Box, Container, Card, Button, InputAdornment, Input, Grid, Tooltip, IconButton, Typography, Divider, CardMedia, CardHeader, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper, Stack, Avatar, alpha, Autocomplete, TextField, PaginationItem, Badge } from '@material-ui/core';
import { Add, Category, Delete, Remove, Search, ShoppingCart } from '@material-ui/icons';
import styled from '@emotion/styled';
import Modal from '../../ProjectModel';
import { useLocation, useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import axios from 'axios';
import { LBS_CONSTANT, REST_API_END_POINT } from 'src/constants/DefaultValues';
import useAuth from 'src/hooks/useAuth';
import Page from '../../../../../components/Page';
import Scrollbar from 'src/components/Scrollbar';
import Pagination from '@material-ui/lab/Pagination';
import { useSnackbar } from 'notistack';
import { empty } from 'src/constants/Helper';
import { AppTopRelated } from 'src/components/_dashboard/general-app';
import { MIconButton } from 'src/components/@material-extend';
import CheckoutConfirmation from 'src/components/_dashboard/pop-ups/CheckoutConfirmation';
import { makeStyles } from '@material-ui/core/styles';
import Label from 'src/components/Label';
import Logo from '../../../../../logo/image.png'
import EmptyContent from 'src/components/EmptyContent';

const CardMediaStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'left',
    paddingTop: 'calc(100% * 9 / 16)',
    '&:before': {
        top: 0,
        zIndex: 9,
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundImage: 'url("https://bagempire-api.gettridant.com/uploads/products/Candy_Runts7.jpg")',
        backgroundSize: 'cover'
    }
}));
const CardMediaStyle1 = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    paddingTop: 'calc(100% * 9 / 10)',
    '&:before': {
        top: 0,
        zIndex: 9,
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
    }
}));
const CoverImgStyle = styled('img')({
    top: 0,
    zIndex: 8,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    padding: '3px',

});

const useStyles = makeStyles((theme) => ({
    active: {
        backgroundColor: '#ccc !important',
      color: '#fff', // White text color for the active page
    },
    root: {
      '& .MuiPaginationItem-root': {
        color: '#000', // Default color for pagination items
      },
    },
  }));

const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%',
    height: '220px',
    boxShadow: '4px 4px 4px -2px #332d2dbd',
    '&:hover': {
        boxShadow: '6px 6px 20px -2px #0000009e',
    },
}));

export const getCookie = (key, type = 'object') => {
    const data = window.localStorage.getItem(key);
    if (!data) return null;

    if (type === 'object') {
        try {
            return JSON.parse(data);
        } catch (e) {
            console.error(`Error parsing JSON from localStorage for key: ${key}`, e);
            return null;
        }
    }

    return data;
};
const getData = (source, ids) => {
    if (!ids) return [];
    return ids.split(',').map(id => source.find(item => item.package_id == id)).filter(item => item !== undefined);
};
const Coupons = [
    { label: 'Sample' },
    { label: 'New' },
    { label: 'Test' },
];
export default function NewShopCard({
    products,
    loadingproducts,
    setLoadingproducts,
    orders,
    deals,
    isPickUp,
    categories,
    customer_id,
    customerData,
    readyToPlaceOrder,
    order_id,
}) {
    const [orderId, setOrderId] = useState(null);
    const { user } = useAuth();
    const [selectedProduct, setProductSelected] = useState({});
    const [finishedProduct, setFinishedProducts] = useState([]);
    const [activeFirstTab, setActiveFirstTab] = useState(0);
    const [allPackages, setAllPackages] = useState([]);
    const [selectedCategory_id, setSelectedCategory_id] = useState(null);
    // const [selectedCategory_id, setSelectedCategory_id] = useState(categories[0]?.category_id);
    const [searchTerm, setSearchTerm] = useState('');
    const [cartItem, setCartItem] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemCount, setSelectedItemCount] = useState(1);
    const [allPackagesQuantities, setAllPackagesQuantities] = useState({});
    const [finishedProductQuantities, setFinishedProductQuantities] = useState({});
    const [dailyLimitExceed, setDailyLimitExceed] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation()
    const [orderItems, setOrderItems] = useState([]);
    const [thcCategories, setThcCategories] = useState({});
    const [customer, setCustomer] = useState({});
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [paymentData, setPaymentData] = useState({
        '1_s': 0,
        '2_s': 0,
        '5_s': 0,
        '10_s': 0,
        '20_s': 0,
        '50_s': 0,
        '100_s': 0,
        'cents': 0,
        'nickles': 0,
        'dimes': 0,
        'quarters': 0,
        '50_cents': 0,
        '1_d': 0,
        'cash': 0,
        'card_name': "",
        'card_number': "",
        'debit': 0,
        'discount': 0,
        'discount_value': 0,
        's_discount_type': 1,
        'discount_reason': "",
        'discount_product': "",
        'coupon_code': "",
        'coupon_discount': 0,
        'points': 0,
        'point_discount': 0,
    })
    const [autoApplyCoupons, setAutoApplyCoupons] = useState([]);
    const [coupon, setCoupon] = useState('');
    const [couponInfos, setCouponInfos] = useState([]);
    const [currencyCode, setCurrencyCode] = useState('USD');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [appliedCouponInfos, setAppliedCouponInfos] = useState([]);
    const [hypurTransactionId, setHypurTransactionId] = useState('');
    const [changeData, setChangeData] = useState(null);
    const [blocking, setBlocking] = useState(false);
    const [blockingOrder, setBlockingOrder] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [fullscreen, setFullscreen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const classes = useStyles();

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
        }
    };
    const [cartTotals, setCartTotals] = useState({
        subtotal: 0,
        discount: 2.4,
        tax: 0,
        total: 0,
        thc_medical: 0,
        thc_edible: 0,
        thc_non_edible: 0,
        auto_apply_discount: 0,
        total_due: 0
    })
    const [currencyRate, setCurrencyRate] = useState(1)
    const OrderData = allPackages?.find(d => d.package_id == cartItem?.package_id)
    const qty_types = {
        '0': 'mg',
        '1': 'g',
        '2': 'oz',
        '3': 'units',
        '4': 'mg',
        '5': 'mg',
        '6': 'mg',
        '7': 'mg',
        '8': '',
        '9': 'units'
    };

    const handleIncrementSelectedItemCountFirstTab = (index) => {
        setAllPackagesQuantities((prevQuantities) => ({
            ...prevQuantities,
            [index]: (prevQuantities[index] || 1) + 1,
        }));
    };
    const handleDecrementSelectedItemCountFirstTab = (index) => {
        setAllPackagesQuantities((prevQuantities) => ({
            ...prevQuantities,
            [index]: Math.max((prevQuantities[index] || 1) - 1, 1),
        }));
    };

    const handleIncrementSelectedItemCount = (index) => {
        setFinishedProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [index]: (prevQuantities[index] || 1) + 1,
        }));
    };

    const handleDecrementSelectedItemCount = (index) => {
        setFinishedProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [index]: Math.max((prevQuantities[index] || 1) - 1, 1),
        }));
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCategoryFilter = (category_id) => {
      if (category_id === null) {
        // Show all categories logic
        setSelectedCategory_id(null);
      } else {
        // Filter categories by category_id logic
        setSelectedCategory_id(category_id);
      }
    };    

    const filteredItems = products?.filter((item) => {
        const itemName = String(item.name).toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();
        const categoryMatch = !selectedCategory_id || item.category_id === selectedCategory_id;

        return itemName.includes(searchTermLower) && categoryMatch;
    });

    const paginatedItems = filteredItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // const paginatedItems = filteredItems

    const handlePageChange = (event, value) => {
        // setCurrentPage(value);
        const maxPage = Math.ceil(filteredItems.length / itemsPerPage); 

        if (value >= 1 && value <= maxPage) {
            setCurrentPage(value);
        } else {
            console.log('Invalid page number');
        }
    };


    // const handleRemoveFromOrder = async (itemName) => {
    //     setOrderItems(prevOrderItems => {
    //         const updatedOrderItems = prevOrderItems.filter(item => item.name !== itemName);
    //         return updatedOrderItems;
    //     });
    //     await recalculateTotals(newData);
    // };

    const handleRemoveFromOrder = async (itemName) => {
        setCartItem(prevCart => {
            const updatedCart = prevCart.filter(item => item.package_id !== itemName);
            console.log('recalculateTotals----1--------');
            recalculateTotals(updatedCart);
            return updatedCart;
        });
    };

    const numberFormat = (number, decimals = 2, decPoint = '.', thousandsSep = ',') => {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        const n = !isFinite(+number) ? 0 : +number;
        const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
        let s = '';

        const toFixedFix = (n, prec) => {
            const k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };

        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
        }

        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }

        return s.join(decPoint);
    };

    // const recalculateTotals = async (inputCart = null, showWarning = false) => {
    //     const currentCart = Array.isArray(inputCart) ? inputCart : Array.isArray(cartItem) ? cartItem : [];
    //     const idTotals = {};
    //     // if (Array.isArray(currentCart)) {
    //     currentCart?.forEach(item => {
    //         const id = item.id;
    //         let adjustTotalQuantity = item?.is_finished === 0 ? item.qty : item.qty * item.variation_weight;

    //         if (!idTotals[id]) {
    //             idTotals[id] = 0;
    //         }

    //         idTotals[id] += adjustTotalQuantity;
    //     });
    //     // }

    //     let coupons = [];
    //     if (coupon !== '') {
    //         coupons = coupon.split(',');
    //     }

    //     let couponDiscount = 0;
    //     let autoApplyDiscount = 0;
    //     const couponDatas = [];

    //     for (const coupon of coupons) {
    //         if (coupon !== '') {
    //             let couponData = appliedCouponInfos.find(r => r.code === coupon);

    //             if (!couponData) {
    //                 const userId = localStorage.getItem('user_id');
    //                 const customerId = customer.customer_id;
    //                 let facilityId = localStorage.getItem('facility_id');
    //                 // facilityId = dataCrypto.decrypt(facilityId);

    //                 const formData = new FormData();
    //                 formData.append('facility_id', facilityId);
    //                 formData.append('user_id', userId);
    //                 formData.append('customer_id', customerId);
    //                 formData.append('coupon', coupon);

    //                 try {
    //                     const res = await axios.post(`${REST_API_END_POINT}point-of-sale/get-applied-coupon-detail`, formData);
    //                     if (res.data.status === 1) {
    //                         couponData = res.data.coupon_data;
    //                         setAppliedCouponInfos(prevState => [...prevState, couponData]);
    //                     }
    //                 } catch (e) {
    //                     console.log("Addition failed, Error", e);
    //                 }
    //             }

    //             if (couponData) {
    //                 if (couponData.is_product === '1' && couponData.type === '0') {
    //                     couponDiscount += parseFloat(couponData.amount);
    //                 }
    //                 couponDatas.push(couponData);
    //             }
    //         }
    //     }

    //     for (const couponData of autoApplyCoupons) {
    //         if (couponData.is_product === '1' && couponData.type === '0') {
    //             autoApplyDiscount += parseFloat(couponData.amount);
    //         }
    //     }

    //     const updatedCartTotals = {
    //         subtotal: 0,
    //         discount: 0,
    //         tax: 0,
    //         total: 0,
    //         thc_medical: 0,
    //         thc_edible: 0,
    //         thc_non_edible: 0,
    //         auto_apply_discount: 0,
    //         total_due: 0
    //     };

    //     const updatedCart = currentCart?.map(row => {
    //         row.thc = row.actual_weight;

    //         if (row?.is_finished !== 1) {
    //             const variationData = row?.variations?.find(r => row.options.variation_id === r.variation_id);
    //             if (variationData) {
    //                 row.max_quantity = parseFloat(row.real_quantity);
    //             }
    //         }

    //         if (autoApplyCoupons.length && row.deal_id === 0) {
    //             for (const couponData of autoApplyCoupons) {
    //                 const productIds = couponData.product_id.split(',');
    //                 const categoryIds = couponData.category_id.split(',');
    //                 let productDiscount = 0;

    //                 if (((productIds.includes(row.id.toString()) && couponData.is_product === '3') ||
    //                     (categoryIds.includes(row.category_id.toString()) && couponData.is_product === '4') ||
    //                     (categoryIds.includes(row.sub_category.toString()) && couponData.is_product === '4') ||
    //                     couponData.is_product === '2') && row.options.is_free === 0) {

    //                     productDiscount = couponData.type === '0' ?
    //                         parseFloat(couponData.amount) :
    //                         ((row.unit_price + row.unit_sales_tax) * row.qty) * parseFloat(couponData.amount) / 100;

    //                     if (productDiscount > parseFloat(((row.unit_price + row.unit_sales_tax) * row.qty).toFixed(2))) {
    //                         productDiscount = 0;
    //                     }
    //                 }

    //                 row.discount = parseFloat(productDiscount.toFixed(2));
    //             }
    //         } else {
    //             row.discount = 0;
    //         }

    //         if (row.coupon_code !== '') {
    //             let couponData = appliedCouponInfos?.find(r => r.code === row.coupon_code);

    //             if (!couponData) {
    //                 const userId = localStorage.getItem('user_id');
    //                 const customerId = customer.customer_id;
    //                 let facilityId = localStorage.getItem('facility_id');
    //                 // facilityId = dataCrypto.decrypt(facilityId);

    //                 const formData = new FormData();
    //                 formData.append('facility_id', facilityId);
    //                 formData.append('user_id', userId);
    //                 formData.append('customer_id', customerId);
    //                 formData.append('coupon', row.coupon_code);

    //                 // try {
    //                 //     const res = await axios.post(`${REST_API_END_POINT}point-of-sale/get-applied-coupon-detail`, formData);
    //                 //     if (res.data.status === 1) {
    //                 //         couponData = res.data.coupon_data;
    //                 //         setAppliedCouponInfos(prevState => [...prevState, couponData]);
    //                 //     }
    //                 // } catch (e) {
    //                 //     console.log("Addition failed, Error", e);
    //                 // }
    //             }

    //             if (couponData) {
    //                 const productIds = couponData.is_product !== '0' ? couponData.product_id.split(',') : [];
    //                 const categoryIds = couponData.is_product !== '0' ? couponData.category_id.split(',') : [];
    //                 let productDiscount = 0;

    //                 if (((productIds.includes(row.id.toString()) && couponData.is_product === '3') ||
    //                     (categoryIds.includes(row.category_id.toString()) && couponData.is_product === '4') ||
    //                     (categoryIds.includes(row.sub_category.toString()) && couponData.is_product === '4') ||
    //                     couponData.is_product === '2' || couponData.is_product === '0') && row.options.is_free === 0) {

    //                     productDiscount = couponData.type === '0' ?
    //                         parseFloat(couponData.amount) :
    //                         ((row.unit_price + row.unit_sales_tax) * row.qty) * parseFloat(couponData.amount) / 100;

    //                     if (productDiscount > parseFloat(((row.unit_price + row.unit_sales_tax) * row.qty).toFixed(2))) {
    //                         productDiscount = 0;
    //                     }
    //                 }

    //                 if (showWarning && productDiscount === 0) {
    //                     enqueueSnackbar("Sorry, this coupon is not applicable now", { variant: 'warning' });
    //                     row.coupon_code = "";
    //                 }
    //                 row.coupon_discount = parseFloat(productDiscount.toFixed(2));
    //             }
    //         } else {
    //             row.coupon_discount = 0;
    //         }
    //         console.log('rowrowrowrowrowrowrowrow',row)
    //         const unitExiseTax = row.unit_exise_tax || 0;
    //         const unitSalesTax = row.unit_sales_tax || 0;
    //         // const unitExiseTax = JSON.parse(JSON.stringify(row.unit_exise_tax));
    //         // const unitSalesTax = JSON.parse(JSON.stringify(row.unit_sales_tax));

    //         if (row.unit_price && row.unit_price !== 0) {
    //             const totPrice = row.unit_price * row.qty - row.discount - row.coupon_discount;
    //             row.price = totPrice / row.qty + unitExiseTax + unitSalesTax;
    //         }

    //         if (row.deal_amount) {
    //             row.deal_amount = row.deal_amount;
    //             row.deal_type = row.deal_type;
    //         }

    //         if (idTotals.hasOwnProperty(row.id)) {
    //             if ((idTotals[row.id] + row.total_quantity) > row.daily_limit_amount) {
    //                 setDailyLimitExceed(true);
    //                 enqueueSnackbar(`Daily Limit Exceed, Daily limit is ${row.daily_limit_amount}g`, { variant: 'warning' });
    //                 row.flag = 1;
    //             } else {
    //                 setDailyLimitExceed(false);
    //                 row.flag = 0;
    //             }
    //         }

    //         row.adjust_total_quantity = row.is_finished === 0 ? row.qty : row.qty * row.variation_weight;

    //         const offPrice = row.deal_amount;
    //         let totalOff = 0;
    //         const formatedValue = numberFormat(parseFloat((row.unit_price * row.qty) * currencyRate).toFixed(2), 2, '.', '');
    //         if (row.deal_type === 0) {
    //             const formatedValueNew = numberFormat(parseFloat(formatedValue - offPrice).toFixed(2), 2, '.', '');
    //             if (formatedValueNew > 0) {
    //                 totalOff = offPrice;
    //             }
    //         } else {
    //             const formatedValueNew = numberFormat(parseFloat(formatedValue - (formatedValue * offPrice / 100)).toFixed(2), 2, '.', '');
    //             if (formatedValueNew > 0) {
    //                 totalOff = parseFloat(formatedValue * offPrice / 100).toFixed(2);
    //             }
    //         }
    //         row.totalOff = totalOff;

    //         row.exise_tax = parseFloat((row.qty * unitExiseTax).toFixed(2));
    //         row.sales_tax = parseFloat((row.qty * unitSalesTax).toFixed(2));
    //         row.tax = row.exise_tax + row.sales_tax;
    //         updatedCartTotals.tax += row.tax;

    //         updatedCartTotals.subtotal += parseFloat(((row.unit_price * row.qty)).toFixed(2));
    //         updatedCartTotals.discount += (
    //             parseFloat(row.discount) +
    //             parseFloat(row.coupon_discount) +
    //             parseFloat(row.totalOff)
    //         );

    //         updatedCartTotals.total += parseFloat((row.unit_price * row.qty - row.discount - row.coupon_discount + row.tax - totalOff)?.toFixed(2));

    //         if (thcCategories?.medical_marijuana?.includes(row.category_id) || thcCategories?.medical_marijuana?.includes(row?.sub_category)) {
    //             updatedCartTotals.thc_medical += parseFloat(row.thc);
    //         } else if (thcCategories?.edibles?.includes(row.category_id) || thcCategories?.edibles?.includes(row?.sub_category)) {
    //             updatedCartTotals.thc_edible += parseFloat(row.thc);
    //         } else if (thcCategories?.non_edibles?.includes(row.category_id) || thcCategories?.non_edibles?.includes(row?.sub_category)) {
    //             updatedCartTotals.thc_non_edible += parseFloat(row.thc);
    //         }

    //         return row;
    //     });

    //     // if (couponDatas.length) {
    //     //     for (const couponData of couponDatas) {
    //     //         if (couponData.is_product === '1' && couponData.type === '1') {
    //     //             couponDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
    //     //         }
    //     //     }
    //     // }

    //     if (updatedCartTotals.total < couponDiscount) {
    //         couponDiscount = 0;
    //     }

    //     // for (const couponData of autoApplyCoupons) {
    //     //     if (couponData.is_product === '1' && couponData.type === '1') {
    //     //         autoApplyDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
    //     //     }
    //     // }

    //     if (updatedCartTotals.total < autoApplyDiscount) {
    //         autoApplyDiscount = 0;
    //     }

    //     updatedCartTotals.discount += parseFloat(couponDiscount + autoApplyDiscount + (((paymentData.discount_value || 0) + (paymentData.point_discount || 0)) / currencyRate));
    //     updatedCartTotals.total -= parseFloat(couponDiscount + autoApplyDiscount + (((paymentData.discount_value || 0) + (paymentData.point_discount || 0)) / currencyRate));

    //     updatedCartTotals.auto_apply_discount = autoApplyDiscount;
    //     updatedCartTotals.total_due = updatedCartTotals.total * currencyRate - (paymentData.cash || 0) - (paymentData.debit || 0);

    //     setCartItem(updatedCart);
    //     setCartTotals(updatedCartTotals);
    //     // setAppliedCouponInfos(appliedCouponInfos);
    // };
    
    const recalculateTotals = async (inputCart = null, showWarning = false) => {
        const currentCart = Array.isArray(inputCart) ? inputCart : Array.isArray(cartItem) ? cartItem : [];
        const idTotals = {};
        // if (Array.isArray(currentCart)) {
        currentCart?.forEach(item => {
            const id = item.id;
            let adjustTotalQuantity = item?.is_finished === 0 ? item.qty : item.qty * item.variation_weight;

            if (!idTotals[id]) {
                idTotals[id] = 0;
            }

            idTotals[id] += adjustTotalQuantity;
        });
        // }

        let coupons = [];
        if (coupon !== '') {
            coupons = coupon.split(',');
        }

        let couponDiscount = 0;
        let autoApplyDiscount = 0;
        const couponDatas = [];

        for (const coupon of coupons) {
            if (coupon !== '') {
                let couponData = appliedCouponInfos.find(r => r.code === coupon);

                if (!couponData) {
                    const userId = localStorage.getItem('user_id');
                    const customerId = customer.customer_id;
                    let facilityId = localStorage.getItem('facility_id');
                    // facilityId = dataCrypto.decrypt(facilityId);

                    const formData = new FormData();
                    formData.append('facility_id', facilityId);
                    formData.append('user_id', userId);
                    formData.append('customer_id', customerId);
                    formData.append('coupon', coupon);

                    try {
                        const res = await axios.post(`${REST_API_END_POINT}point-of-sale/get-applied-coupon-detail`, formData);
                        if (res.data.status === 1) {
                            couponData = res.data.coupon_data;
                            setAppliedCouponInfos(prevState => [...prevState, couponData]);
                        }
                    } catch (e) {
                        console.log("Addition failed, Error", e);
                    }
                }

                if (couponData) {
                    if (couponData.is_product === '1' && couponData.type === '0') {
                        couponDiscount += parseFloat(couponData.amount);
                    }
                    couponDatas.push(couponData);
                }
            }
        }

        for (const couponData of autoApplyCoupons) {
            if (couponData.is_product === '1' && couponData.type === '0') {
                autoApplyDiscount += parseFloat(couponData.amount);
            }
        }

        const updatedCartTotals = {
            subtotal: 0,
            discount: 0,
            tax: 0,
            total: 0,
            thc_medical: 0,
            thc_edible: 0,
            thc_non_edible: 0,
            auto_apply_discount: 0,
            total_due: 0
        };

        const updatedCart = currentCart?.map(row => {
          
          row.thc = row.actual_weight;
          if (!row.price_add) {
            let variationData = row?.variations?.find((varItem) => row.qty >= varItem?.min_value && row.qty <= varItem?.max_value);

            if (!variationData) {
                variationData = row?.variations?.reduce((prev, current) => {
                  return current.max_value > prev.max_value ? current : prev;
                }, { max_value: -Infinity });
            }

            row.price = variationData?.price || 0
            row.unit_price = variationData?.unit_price  || 0
            row.unit_sales_tax = variationData?.tax || 0
            row.variation_id = variationData?.variation_id || 0

            if (row?.is_finished !== 1 && variationData) {
                const variationDatas = row?.variations?.find(r => row.options.variation_id === variationData?.variation_id);
                if (variationDatas) {
                    row.max_quantity = parseFloat(row.real_quantity);
                }
            }
          }
            

            if (autoApplyCoupons.length && row.deal_id === 0) {
                for (const couponData of autoApplyCoupons) {
                    const productIds = couponData.product_id.split(',');
                    const categoryIds = couponData.category_id.split(',');
                    let productDiscount = 0;

                    if (((productIds.includes(row.id.toString()) && couponData.is_product === '3') ||
                        (categoryIds.includes(row.category_id.toString()) && couponData.is_product === '4') ||
                        (categoryIds.includes(row.sub_category.toString()) && couponData.is_product === '4') ||
                        couponData.is_product === '2') && row.options.is_free === 0) {

                        productDiscount = couponData.type === '0' ?
                            parseFloat(couponData.amount) :
                            ((row.unit_price + row.unit_sales_tax) * row.qty) * parseFloat(couponData.amount) / 100;

                        if (productDiscount > parseFloat(((row.unit_price + row.unit_sales_tax) * row.qty).toFixed(2))) {
                            productDiscount = 0;
                        }
                    }

                    row.discount = parseFloat(productDiscount.toFixed(2));
                }
            } else {
                row.discount = 0;
            }

            if (row.coupon_code !== '') {
                let couponData = appliedCouponInfos?.find(r => r.code === row.coupon_code);

                if (!couponData) {
                    const userId = localStorage.getItem('user_id');
                    const customerId = customer.customer_id;
                    let facilityId = localStorage.getItem('facility_id');
                    // facilityId = dataCrypto.decrypt(facilityId);

                    const formData = new FormData();
                    formData.append('facility_id', facilityId);
                    formData.append('user_id', userId);
                    formData.append('customer_id', customerId);
                    formData.append('coupon', row.coupon_code);

                    // try {
                    //     const res = await axios.post(`${REST_API_END_POINT}point-of-sale/get-applied-coupon-detail`, formData);
                    //     if (res.data.status === 1) {
                    //         couponData = res.data.coupon_data;
                    //         setAppliedCouponInfos(prevState => [...prevState, couponData]);
                    //     }
                    // } catch (e) {
                    //     console.log("Addition failed, Error", e);
                    // }
                }

                if (couponData) {
                    const productIds = couponData.is_product !== '0' ? couponData.product_id.split(',') : [];
                    const categoryIds = couponData.is_product !== '0' ? couponData.category_id.split(',') : [];
                    let productDiscount = 0;

                    if (((productIds.includes(row.id.toString()) && couponData.is_product === '3') ||
                        (categoryIds.includes(row.category_id.toString()) && couponData.is_product === '4') ||
                        (categoryIds.includes(row.sub_category.toString()) && couponData.is_product === '4') ||
                        couponData.is_product === '2' || couponData.is_product === '0') && row.options.is_free === 0) {

                        productDiscount = couponData.type === '0' ?
                            parseFloat(couponData.amount) :
                            ((row.unit_price + row.unit_sales_tax) * row.qty) * parseFloat(couponData.amount) / 100;

                        if (productDiscount > parseFloat(((row.unit_price + row.unit_sales_tax) * row.qty).toFixed(2))) {
                            productDiscount = 0;
                        }
                    }

                    if (showWarning && productDiscount === 0) {
                        enqueueSnackbar("Sorry, this coupon is not applicable now", { variant: 'warning' });
                        row.coupon_code = "";
                    }
                    row.coupon_discount = parseFloat(productDiscount.toFixed(2));
                }
            } else {
                row.coupon_discount = 0;
            }
            console.log('rowrowrowrowrowrowrowrow',row)
            const unitExiseTax = row.unit_exise_tax || 0;
            const unitSalesTax = row.unit_sales_tax || 0;
            // const unitExiseTax = JSON.parse(JSON.stringify(row.unit_exise_tax));
            // const unitSalesTax = JSON.parse(JSON.stringify(row.unit_sales_tax));

            if (row.unit_price && row.unit_price !== 0) {
                const totPrice = row.unit_price - row.discount - row.coupon_discount;
                row.price = totPrice + unitExiseTax + unitSalesTax;
            }

            if (row.deal_amount) {
                row.deal_amount = row.deal_amount;
                row.deal_type = row.deal_type;
            }

            // if (idTotals.hasOwnProperty(row.id)) {
            //     if ((idTotals[row.id] + row.total_quantity) > row.daily_limit_amount) {
            //         setDailyLimitExceed(true);
            //         enqueueSnackbar(`Daily Limit Exceed, Daily limit is ${row.daily_limit_amount}g`, { variant: 'warning' });
            //         row.flag = 1;
            //     } else {
            //         setDailyLimitExceed(false);
            //         row.flag = 0;
            //     }
            // }

            row.adjust_total_quantity = row.is_finished === 0 ? row.qty : row.qty;

            const offPrice = row.deal_amount;
            let totalOff = 0;
            const formatedValue = parseFloat((row.unit_price) * currencyRate)?.toFixed(2);
            if (row.deal_type === 0) {
                const formatedValueNew = parseFloat(formatedValue - offPrice)?.toFixed(2);
                if (formatedValueNew > 0) {
                    totalOff = offPrice;
                }
            } else {
                const formatedValueNew = parseFloat(formatedValue - (formatedValue * offPrice / 100))?.toFixed(2);
                if (formatedValueNew > 0) {
                    totalOff = parseFloat(formatedValue * offPrice / 100).toFixed(2);
                }
            }
            row.totalOff = totalOff;

            // row.exise_tax = parseFloat((unitExiseTax).toFixed(2));
            // row.sales_tax = parseFloat((unitSalesTax).toFixed(2));
            row.exise_tax = parseFloat((row.qty * unitExiseTax).toFixed(2));
            row.sales_tax = parseFloat((row.qty * unitSalesTax).toFixed(2));
            row.tax = row.exise_tax + row.sales_tax;
            updatedCartTotals.tax += row.tax;

        
            

            updatedCartTotals.subtotal += parseFloat(((row.unit_price * row.qty)).toFixed(2));
            updatedCartTotals.discount += (
                parseFloat(row.discount) +
                parseFloat(row.coupon_discount) +
                parseFloat(row.totalOff)
            );
    //         updatedCartTotals.total += parseFloat((row.unit_price * row.qty - row.discount - row.coupon_discount + row.tax - totalOff)?.toFixed(2));

            updatedCartTotals.total += parseFloat((row.unit_price * row.qty - row.discount - row.coupon_discount + row.tax - totalOff)?.toFixed(2));

            if (thcCategories?.medical_marijuana?.includes(row.category_id) || thcCategories?.medical_marijuana?.includes(row?.sub_category)) {
                updatedCartTotals.thc_medical += parseFloat(row.thc);
            } else if (thcCategories?.edibles?.includes(row.category_id) || thcCategories?.edibles?.includes(row?.sub_category)) {
                updatedCartTotals.thc_edible += parseFloat(row.thc);
            } else if (thcCategories?.non_edibles?.includes(row.category_id) || thcCategories?.non_edibles?.includes(row?.sub_category)) {
                updatedCartTotals.thc_non_edible += parseFloat(row.thc);
            }

            return row;
        });

        // if (couponDatas.length) {
        //     for (const couponData of couponDatas) {
        //         if (couponData.is_product === '1' && couponData.type === '1') {
        //             couponDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
        //         }
        //     }
        // }

        if (updatedCartTotals.total < couponDiscount) {
            couponDiscount = 0;
        }

        // for (const couponData of autoApplyCoupons) {
        //     if (couponData.is_product === '1' && couponData.type === '1') {
        //         autoApplyDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
        //     }
        // }

        if (updatedCartTotals.total < autoApplyDiscount) {
            autoApplyDiscount = 0;
        }

        updatedCartTotals.discount += parseFloat(couponDiscount + autoApplyDiscount + (((paymentData.discount_value || 0) + (paymentData.point_discount || 0)) / currencyRate));
        updatedCartTotals.total -= parseFloat(couponDiscount + autoApplyDiscount + (((paymentData.discount_value || 0) + (paymentData.point_discount || 0)) / currencyRate));

        updatedCartTotals.auto_apply_discount = autoApplyDiscount;
        updatedCartTotals.total_due = updatedCartTotals.total * currencyRate - (paymentData.cash || 0) - (paymentData.debit || 0);

        setCartItem(updatedCart);
        setCartTotals(updatedCartTotals);
        // setAppliedCouponInfos(appliedCouponInfos);
    };

    useEffect(async () => {
        const savedCartItem = localStorage.getItem('cartItem');
        if (savedCartItem) {
            setCartItem(JSON.parse(savedCartItem));
        }
        console.log('recalculateTotals----2--------');
        await recalculateTotals(JSON.parse(savedCartItem));
        setOrderId(order_id)
    }, []);

    // useEffect(() => {
    //     localStorage.setItem('cartItem', JSON.stringify(cartItem));
    // }, [cartItem]);

    const handleAddToOrder = (data,
      // quantity
    ) => {
      setBlockingOrder(true);
      console.log('=======data', data)
      // setCartItem(prevCartItems => {
      //     const updatedCart = [...prevCartItems, data];      
      //     return updatedCart;
      // });//for now 
      let cartData = {}
      var bogo_code = getCookie('bogo_code', 'string');
      let existingCart = getCookie('cart', 'object');
      try {
        axios.post(REST_API_END_POINT + 'point-of-sale/add-to-cart', {
          facility_id: user.facility_id,
          user_id: user.id,
          customer_id,
          bogo_code: bogo_code,
          free_product: 0,
          parent_product: 0,
          product_id: data?.product_id,
          existing_cart: cartItem || [],
          product_price: data?.product_price || 0,
          product_unit_price: data?.product_unit_price || 0,
          tax_rate: data?.tax_rate || 0,
          exise_tax: 0,
          sales_tax: 0,
          quantity: 1,
          variation_id: data?.variation_id,
          package_id: data?.package_id,
          is_finished: data?.package_type == 1 ? 0 : 1,
        }).then((res) => {
          if (res.data.status === 1) {
            cartData = res.data.cart_item
            setCartItem(prevCartItems => {
              const updatedCart = [...prevCartItems, cartData];
              handleOrderUpdate(updatedCart, cartData);
              return updatedCart;
            });
            setSelectedItem(null)
          } else {
            enqueueSnackbar(res.data.message, { variant: 'warning' })
          }
        })
      } catch (error) {
        console.log('handleAddToOrder error : ', error);
      }finally{
        setTimeout(() => {
          setBlockingOrder(false);
        }, 500);
      }
    };
    const handleOrderUpdate = (updatedCart, cartData) => {
        const existingOrderItemIndex = updatedCart.findIndex(
            (item) => item.barcode === cartData.barcode
        );

        if (existingOrderItemIndex !== -1) {
            const updatedOrderItems = [...updatedCart];
            updatedOrderItems[existingOrderItemIndex].count += selectedItemCount;
            setOrderItems(updatedOrderItems);
            console.log('recalculateTotals----3--------');
            recalculateTotals(updatedOrderItems);
        } else {
            const updatedCartItem = {
                ...cartData,
                count: selectedItemCount,
            };
            const updatedOrderItems = [...updatedCart, updatedCartItem];
            setOrderItems(updatedOrderItems);
            console.log('recalculateTotals----4--------');
            recalculateTotals(updatedOrderItems);
        }
        setSelectedItem(null);
        setSelectedItemCount(1);
    };



    const handleClickOpen = (item) => {
      setBlockingOrder(true);
      try{
        setSelectedItem(item);
        // console.log(item,'---------------item')
        axios.post(`${REST_API_END_POINT}point-of-sale/get-packages`, {
            user_id: user.id, facility_id: user.facility_id,
            product_id: item.id,
            free_product: 0,
            free: 0,
            parent_product: 0,
            bogo_id: 0,
        })
        .then((res) => {
          if (res.data.status === 1) {
            const data = res.data;
            let product = data.product;
            let all_packages = data.all_packages;
            let finished_products = data.finished_products;

            all_packages = all_packages.map(row => {
                let qty_type = 'Units';
                let quantity = parseFloat(row.quantity).toFixed(2);

                if (product.qty_type === 1) {
                    qty_type = 'Grams';
                    quantity = row.quantity;
                } else if (product.qty_type === 2) {
                    qty_type = 'Lbs';
                    quantity = (parseFloat(row.quantity) / LBS_CONSTANT).toFixed(2);
                }

                row.available = `${quantity} ${qty_type} available in stock`;
                return row;
            });

            finished_products = finished_products?.map(row => {
              let qty_type = qty_types[row.variation_type];
              let capacity = row.capacity;

              if (row.variation_type === 2) {
                  if (capacity === 0.125) {
                      capacity = '1/8';
                  } else if (capacity === 0.25) {
                      capacity = '1/4';
                  } else if (capacity === 0.5) {
                      capacity = '1/2';
                  } else {
                      capacity = parseFloat(capacity).toFixed(2);
                  }
              } else {
                  capacity = parseFloat(capacity).toFixed(2);
              }

              row.available = `${row.variation_name} (${capacity} ${qty_type}) Only ${parseInt(row.quantity)} quantity left in stock`;
              return row;
            });


            setProductSelected(product);
            setAllPackages(all_packages);
            setFinishedProducts(finished_products);
            
            console.log('finished_products-item--',finished_products[0]);
            console.log('finished_products-cartItem-item--',cartItem);
            if (finished_products[0] && finished_products[0]?.quantity > 0) {
              const cartItemData = cartItem?.find((item)=>item?.package_id == finished_products[0]?.package_id)
              console.log('finished_products-cartItemData-item--',cartItemData);
              if(cartItemData){
                enqueueSnackbar('Already added to cart', { variant: 'error' })
                setBlockingOrder(false);
              }else{
                handleAddToOrder(finished_products[0])
              }
            } else {
              enqueueSnackbar('No finished products available', { variant: 'warning' })
              setBlockingOrder(false);
            }                    
          }
        })
        .catch((err) => {
            console.log(err);
            setBlockingOrder(false);
        });
      }catch(error){
        console.log('Handle click open error : ',error);
        setBlockingOrder(false);
      }
    };

    const handleClose = () => {
        setSelectedItem(null);
    };

    // const handleIncrementItemCount = async (itemName,maximumStock) => {
    //     const newData = cartItem.map(item =>
    //         item.package_id === itemName ? { ...item, qty: item.qty + 1 } : item
    //     );
    //     setCartItem(newData);
    //     await recalculateTotals(newData);
    // };

    const handleIncrementItemCount =  (itemName, maximumStock) => {
        const newData = cartItem.map(item => {
            if (item.package_id === itemName) {
                
                const newQty = item.qty + 1;
                if (newQty <= maximumStock) {
                    return { ...item, qty: newQty ,};
                } else {
                       enqueueSnackbar("Cannot add more items, maximum stock reached.", { variant: 'warning' })

                    return item;
                }
            }
            return item;
        });
    
        setCartItem(newData);
        console.log('recalculateTotals----5--------');
        // await recalculateTotals(newData);
        recalculateTotalsssss(newData)
    };
    
    const handleDecrementItemCount =  (itemName) => {
        const newData = cartItem.map(item =>
            item.package_id === itemName && item.qty > 1 ? { ...item, qty: item.qty - 1,} : item
        );
        setCartItem(newData);
        console.log('recalculateTotals----6--------');
        // await recalculateTotals(newData);
        recalculateTotalsssss(newData)
    };

        
    // const handlePriceChange = async (itemName, newPrice) => {
    //     const updatedPrice = parseFloat(newPrice) || 1;
    
    //     console.log("updatedPriceupdatedPriceupdatedPrice",updatedPrice)
    //     let taxAmount =(updatedPrice * 5/100).toFixed(2) || 0
    //     const newData = cartItem.map(item => {
    //         if (item.package_id === itemName) {
    //             return { ...item, unit_price: updatedPrice ,price_add: true ,unit_sales_tax:taxAmount};
    //         }
    //         return item;
    //     });
    //   console.log(newData,'-------------newData')
    //   setCartItem(newData);
    // //   await recalculateTotals(newData);
    //   recalculateTotalsssss(newData);
    // };


    const handlePriceChange = (itemName, newPrice, tax_rate=0) => {
      

      // let sanitizedPrice = newPrice.replace(/[^\d.]/g, '');
      let sanitizedPrice = newPrice.replace(/[^\d.]/g, '');

      // let updatedPrice = parseFloat(newPrice) || 1;
      let updatedPrice = parseFloat(sanitizedPrice) || 0;
      const taxAmount = (updatedPrice * Number(tax_rate) / 100).toFixed(2); 

      if (updatedPrice < 0 || isNaN(updatedPrice)) {
        updatedPrice = 0;

        const newData = cartItem.map(item => {
          if (item.package_id === itemName) {
              return { 
                  ...item, 
                  unit_price: 0,
                  price_add: true,
                  unit_sales_tax: 0, 
                  sales_tax:0,
                  product_unit_price:0
              };
          }
          return item;
      });

           // Set the updated cart items
           setCartItem(newData);
  
           // Recalculate the totals after updating the price and tax
           recalculateTotalsssss(newData);
      }else{

      
  
      // Update the cart item with the new price and calculated tax
      const newData = cartItem.map(item => {
          if (item.package_id === itemName) {
              return { 
                  ...item, 
                  unit_price: updatedPrice, // Update the price
                  price_add: true, // Flag that price has been updated
                  unit_sales_tax: taxAmount, // Set the calculated tax amount
                  sales_tax:taxAmount,
                  product_unit_price:updatedPrice,

              };
          }
          return item;
      });
  
      // Set the updated cart items
      setCartItem(newData);
  
      // Recalculate the totals after updating the price and tax
      recalculateTotalsssss(newData);
    }
  };
  

    const recalculateTotalsssss = (currentCart) => {
        const updatedCartTotals = {
            subtotal: 0,
            discount: 0,
            tax: 0,
            total: 0,
            auto_apply_discount: 0,
            total_due: 0
        };
    
        currentCart?.forEach((item) => {
            // Apply variations if `price_add` is false, otherwise use the changed `unit_price`
            if (!item.price_add) {
                let variationData = item?.variations?.find(
                    (varItem) => item.qty >= varItem?.min_value && item.qty <= varItem?.max_value
                );
    
                if (!variationData) {
                    variationData = item?.variations?.reduce((prev, current) => {
                        return current.max_value > prev.max_value ? current : prev;
                    }, { max_value: -Infinity });
                }
                
    
                // Use the variation price if price_add is false
                item.unit_price = variationData?.unit_price || 0;
                item.unit_sales_tax = variationData?.tax || 0;
                
            }
    
            // Calculate tax and other amounts
            
            // item.tax = item.exise_tax + item.unit_sales_tax;
            item.tax = (item.exise_tax + item.unit_sales_tax) * item.qty
            updatedCartTotals.tax += item.tax;
    
            updatedCartTotals.subtotal += parseFloat((item.unit_price * item.qty).toFixed(2));
            updatedCartTotals.discount += (
                parseFloat(item.discount) +
                parseFloat(item.coupon_discount) +
                parseFloat(item.totalOff)
            );
    
            updatedCartTotals.total += parseFloat((item.unit_price * item.qty - item.discount - item.coupon_discount + item.tax)?.toFixed(2));
        });
    
        // Update total_due based on currencyRate and set the new totals
        setCartTotals({
            ...updatedCartTotals,
            total_due: updatedCartTotals.total * currencyRate,
        });
    };
    
    

    const calculateSubtotal = () => {
        let subtotal = 0;
        orderItems.forEach((orderItem) => {
            subtotal += orderItem.price * orderItem.count;
        });
        return subtotal.toFixed(2);
    };

    const calculateTaxes = () => {
        const subtotal = calculateSubtotal();
        const taxRate = 0.06;
        const taxes = subtotal * taxRate;
        return taxes.toFixed(2);
    };

    const calculateGeneralfee = () => {
        const uniqueProducts = new Set();
        orderItems.forEach((orderItem) => {
            uniqueProducts.add(orderItem.name);
        });
        const productCount = uniqueProducts.size;
        const generalfee = productCount * 15;
        return generalfee.toFixed(2);
    };

    const calculateMetricFee = () => {
        const uniqueProducts = new Set();
        orderItems.forEach((orderItem) => {
            uniqueProducts.add(orderItem.name);
        });
        const productCount = uniqueProducts.size;
        const metricFee = productCount * 10;
        return metricFee.toFixed(2);
    };

    const handleTabChange = (event, newValue) => {
        setActiveFirstTab(newValue);
    };


    const cartItemIds = new Set(cartItem?.map(item => item?.package_id));
    const filteredFinishedProducts = finishedProduct?.filter(item => !cartItemIds?.has(item.package_id));
    const filteredAllPackages = allPackages?.filter(item => !cartItemIds?.has(item.package_id));

    const placeOrderFinal = () => {
        setBlocking(true);

        const checkoutMode = 'Express';
        const bogoCode = getCookie('bogo_code', 'string');

        let updatedCart = cartItem.map((row) => {
            row.unit_price *= currencyRate;
            row.price *= currencyRate;
            row.discount *= currencyRate;
            row.exise_tax *= currencyRate;
            row.sales_tax *= currencyRate;
            row.tax *= currencyRate;
            row.free_discount *= currencyRate;
            row.coupon_discount *= currencyRate;
            return row;
        });
        // const flag = updatedCart.some((row) => row.flag === 1);
        const flag = false

        // const formData = new FormData();
        // if (readyToPlaceOrder) {
        //     formData.append('order_id', orderId);
        //     formData.append('ready_for_order_sale', true)
        //     formData.append('is_pickup', 1);
        // } else {
        //     formData.append('is_pickup', isPickUp ? 1 : 0);
        // }
        // formData.append('first_name', customerData.vendor_name);
        // formData.append('email_id', customerData.email_id);
        // formData.append('phone', customerData.phone);
        // formData.append('bill_address', customerData.vendor_address);
        // formData.append('ship_address', customerData.vendor_address);
        // formData.append('facility_id', user.facility_id);
        // formData.append('user_id', user.id);
        // formData.append('customer_id', customer_id);
        // formData.append('bogo_code', bogoCode);
        // formData.append('currency_code', currencyCode);
        // formData.append('currency_rate', currencyRate);
        // formData.append('checkout_mode', checkoutMode);
        // formData.append('delivery_date', deliveryDate);
        // formData.append('payment_data', JSON.stringify(paymentData));
        // formData.append('hypur_transaction_id', hypurTransactionId);
        // if (changeData) {
        //     formData.append('change', JSON.stringify(changeData));
        // }
        // formData.append('cart_products', JSON.stringify(updatedCart));
        // formData.append('auto_apply_discount', cartTotals.auto_apply_discount * currencyRate);
        // formData.append('total_tax', cartTotals.tax * currencyRate);
        // formData.append('thc_medical', cartTotals.thc_medical);
        // formData.append('thc_edible', cartTotals.thc_edible);
        // formData.append('thc_non_edible', cartTotals.thc_non_edible);
        // formData.append('total_thc', parseFloat((cartTotals.thc_medical + cartTotals.thc_edible + cartTotals.thc_non_edible).toFixed(2)));

        const formData = {
          order_id: readyToPlaceOrder ? orderId : undefined,
          ready_for_order_sale: readyToPlaceOrder ? true : undefined,
          is_pickup: readyToPlaceOrder ? 1 : (isPickUp ? 1 : 0),
          first_name: customerData.vendor_name,
          email_id: customerData.email_id,
          phone: customerData.phone,
          bill_address: customerData.vendor_address,
          ship_address: customerData.vendor_address,
          facility_id: user.facility_id,
          user_id: user.id,
          customer_id: customer_id,
          bogo_code: bogoCode,
          currency_code: currencyCode,
          currency_rate: currencyRate,
          checkout_mode: checkoutMode,
          delivery_date: deliveryDate,
          payment_data: paymentData, // Already an object, so no need to stringify
          hypur_transaction_id: hypurTransactionId,
          change: changeData ? changeData : undefined, // Only include if changeData exists
          cart_products: updatedCart, // Assuming it's already an array or object
          auto_apply_discount: cartTotals.auto_apply_discount * currencyRate,
          total_tax: cartTotals.tax * currencyRate,
          thc_medical: cartTotals.thc_medical,
          thc_edible: cartTotals.thc_edible,
          thc_non_edible: cartTotals.thc_non_edible,
          total_thc: parseFloat((cartTotals.thc_medical + cartTotals.thc_edible + cartTotals.thc_non_edible).toFixed(2)),
        };

        if (flag == false) {
            axios.post(REST_API_END_POINT + 'point-of-sale/place-order', formData)
                .then((res) => {
                    const data = res.data;

                    if (data.status === 1) {
                        setCustomerId(null)
                        // setCartItem([])

                        // setCookie('bogo_code', null);
                        setPaymentData({})
                        setChangeData(null)
                        setBlocking(false);
                        setOrderId(data.order_id);
                        // setSuccessMsg(data.msg);
                        if (isPickUp || readyToPlaceOrder) {
                            if (readyToPlaceOrder) {
                                localStorage.removeItem('cartItem')
                            }
                            navigate(PATH_DASHBOARD.pov.orderAhead)
                        } else {
                            localStorage.removeItem('cartItem')
                            navigate(PATH_DASHBOARD.pov.PaymentProcess, { state: { orderId: data?.order_id, cartTotals: cartTotals } })
                        }
                        enqueueSnackbar('The Order has been placed successfully', { variant: 'success' })
                        localStorage.setItem('message', data.msg);

                        // window.location = "/app/point-of-sale/process-payment/" + data.order_id;
                    } else {
                        enqueueSnackbar(data.msg, { variant: 'error' })
                        setBlocking(false);
                    }
                })
                .catch((e) => {
                    console.error("Addition failed, Error ", e);
                    setBlocking(false);
                });
        } else {
            // enqueueSnackbar("Daily limit exceed", { variant: 'error' })
            // //   swal("Warning!", "Daily limit exceed", "warning");
             setBlocking(false);
        }
    };

    const placeOrder = () => {
        if (cartItem.length) {
          const itemWithZero = cartItem?.some((newItem) => newItem.unit_price <= 0)
          console.log('cartItemcartItem-itemWithZero--',itemWithZero);
          if(itemWithZero){
            enqueueSnackbar('Order cannot be placed with items that have no price', { variant: 'error' })
            return;
          }
            let proceed = true;
            for (let row of cartItem) {
                if (row.max_quantity < row.qty) {
                    proceed = false;
                    break;
                }
            }
            if (proceed) {
                let facilityId = user.facility_id;
                // facilityId = dataCrypto.decrypt(facilityId);
                if (facilityId === '10') {
                    window.location = "/app/point-of-sale/place-order";
                } else {
                    placeOrderFinal();
                }
            }
            else {
                enqueueSnackbar("Please check the quantity in cart", { variant: 'warning' })
            }
        } else {
            // enqueueSnackbar("There are no products in the cart",{variant:'error'})
            setConfirmDialogOpen(true);
            // .then((willDelete)=>{
            //     if(willDelete){
            //         axios.post(`${REST_API_END_POINT}point-of-sale/checkout_customer`, {customer_id})
            //         .then((res)=>{
            //             setCartItem(null)
            //             navigate(PATH_DASHBOARD.pov.CustomerLookup)
            //         })
            //         .catch(e=>{
            //             console.log("Addition failed, Error ", e);
            //         })
            //     }
            //         else{
            //             enqueueSnackbar("Continue ordering!",{variant:'error'});
            //         }
            // })
        }
    }

    const handleCheckoutConfirm = () => {
        axios.post(`${REST_API_END_POINT}point-of-sale/checkout_customer`, { customer_id })
            .then((res) => {
                setCartItem(null)
                navigate(PATH_DASHBOARD.pov.CustomerLookup)
            })
            .catch(e => {
                console.log("Checkout failed, Error: ", e);
                enqueueSnackbar("Checkout failed", { variant: 'error' });
            });
        setConfirmDialogOpen(false);
    };

    const handleDialogCancel = () => {
        enqueueSnackbar("Continue ordering!", { variant: 'info' });
        setConfirmDialogOpen(false);
    };

    console.log('cartitem-456-----',cartItem)

    return (
      <Page title={user?.facility_name || 'Get Go'}>
        <Grid
          container
          xs={12}
          md={12}
          spacing={1}
          mt={1}
          sx={{ pl: { xs: 1, sm: 1, md: 3 } }}
        >
          {/* <Grid container spacing={2} > */}
          <Grid item xs={12} md={1.8} spacing={2} borderRadius={'10px'}>
            <Card
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #000',
                backdropFilter: 'blur(8px)',
                WebkitBackdropFilter: 'blur(8px)',
              }}
            >
              <Scrollbar sx={{ height: '595px' }}>
                <Stack
                  container
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100px',
                    p: 1.5,
                    justifyContent: 'center',
                  }}
                  onClick={() => handleCategoryFilter(null)}
                >
                  <Stack
                    direction={'column'}
                    sx={{
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      borderRadius: 0.8,
                      background:
                        selectedCategory_id === null 
                          ? 'linear-gradient(to left, #000,#000,#000,#000)'
                          : 'common.white',
                      color: selectedCategory_id === null ? 'common.white' : 'black',
                      '&:hover': {
                        background: 'linear-gradient(to left, #000,#000,#000,#000)',
                        color: 'white',
                      },
                    }}
                  >
                    <Stack
                      className="mdi--marijuanaa"
                      sx={{ fontSize: '1.5rem', mb: 0.6 }}
                    />
                    <Tooltip title="All Categories">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          maxWidth: 130,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        All Categories
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Divider sx={{ borderColor: '#000' }} />
                {categories.map((data, index) => (
                  <div key={index}>
                    <Stack
                      container
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100px',
                        p: 1.5,
                        justifyContent: 'center',
                      }}
                      onClick={() => handleCategoryFilter(data.category_id)}
                    >
                      <Stack
                        direction={'column'}
                        sx={{
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          borderRadius: 0.8,
                          background:
                            selectedCategory_id === data.category_id
                              ? 'linear-gradient(to left, #000,#000,#000,#000)'
                              : 'common.white',
                          color:
                            selectedCategory_id === data.category_id
                              ? 'common.white'
                              : 'black',
                          '&:hover': {
                            background: 'linear-gradient(to left, #000,#000,#000,#000)',
                            color: 'white',
                          },
                        }}
                      >
                        <Stack
                          className="mdi--marijuanaa"
                          sx={{ fontSize: '1.5rem', mb: 0.6 }}
                        />
                        <Tooltip title={data.name}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              maxWidth: 130,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {data.name}
                          </Typography>
                        </Tooltip>
                      </Stack>
                    </Stack>
                    <Divider sx={{ borderColor: '#000' }} />
                  </div>
                ))}
              </Scrollbar>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} spacing={2}>
            <Grid
              sx={{
                display: 'flex',
                bgcolor: 'transparent',
                borderRadius: '15px',
                p: 1,
                mx: '8px',
                border: '1px solid #000',
              }}
            >
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search…"
                startAdornment={
                  <InputAdornment position="start">
                    <Search sx={{ color: 'black', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                sx={{
                  mr: 1,
                  fontWeight: 'fontWeightBold',
                  '&::placeholder': { color: 'black' },
                  input: {
                    color: 'black',
                    '&::placeholder': {
                      color: 'black',
                    },
                  },
                }}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Grid>
            <Scrollbar sx={{ height: '534px', mt: 1, pl: 1, pr: 1 }}>
              <Grid container spacing={2} mt={0.3} sx={{ textAlign: 'center' }}>
                {loadingproducts ? (
                  <>
                    <Typography
                      variant="h6"
                      sx={{ width: '100%', textAlign: 'center', mt: 5 }}
                    >
                      Please wait...
                    </Typography>
                    <Box sx={{ width: '100%', justifyContent: 'center' }}>
                      <Stack className="svg-spinners--6-dots-rotate"></Stack>
                    </Box>
                  </>
                ) : paginatedItems.length === 0 ? (
                  <Typography
                    variant="h6"
                    sx={{ width: '100%', textAlign: 'center', mt: 5 }}
                  >
                    No available products
                  </Typography>
                ) : (
                  paginatedItems?.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={6}
                      md={3}
                      sm={3}
                      onClick={() => {
                        if (blockingOrder) return;
                        handleClickOpen(item);
                      }}
                    >
                      <Card
                        sx={{
                          cursor: 'pointer',
                          borderRadius: '0px',
                          backgroundColor: 'transparent',
                          border: '1px solid #000',
                          backdropFilter: 'blur(8px)',
                          WebkitBackdropFilter: 'blur(8px)',
                        }}
                      >                  
                        <CardMediaStyle1>
                          <CoverImgStyle
                            alt="cover"
                            src={
                              item.thumbnail && item.thumbnail != 'default.jpg'
                                ? item.thumbnail
                                : Logo
                            }
                          />
                        </CardMediaStyle1>
                        <Divider sx={{ borderColor: '#000' }} />
                        <Tooltip title={item.name}>
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{
                              mt: 1.5,
                              maxWidth: 180,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              pr: 2,
                              pl: 2,
                              textAlign: 'center',
                              color: 'text.primary',
                              '&:hover': { color: 'primary.main' },
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="caption"
                          align="center"
                          sx={{
                            // mb: 1.2,
                            mt: 2,
                            maxWidth: 180,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            pr: 2,
                            pl: 2,
                            textAlign: 'center',
                            color: '#d50000',
                            '&:hover': { color: 'primary.main' },
                          }}
                        >
                          {`(${item.product_stock < 0? 0 : item.product_stock} Qty)`}
                        </Typography>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    disabled={
                      item.type === 'start-ellipsis' ||
                      item.type === 'end-ellipsis'
                    } // Disable interaction for ellipsis
                    style={
                      item.type === 'start-ellipsis' ||
                      item.type === 'end-ellipsis'
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                    classes={{
                      selected: classes.active,
                    }}
                  />
                )}
              />
              {/* <Modal open={!!selectedItem} handleClose={handleClose} onClose={handleClose}
                            modalTitle={'Choose Package'}  >
                            <Divider />
                            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <Tabs
                                    value={activeFirstTab}
                                    onChange={handleTabChange}
                                    aria-label="product tabs"
                                >
                                    <Tab label="All Packages" />
                                    <Tab label="Finished Products" />
                                </Tabs>
                            </Box>
                            <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                                <Table sx={{ my: 3, whiteSpace: 'nowrap' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Barcode</TableCell>
                                            <TableCell align="left">Available</TableCell>                                 
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {selectedItem && activeFirstTab === 0 && (
                                        <TableBody>
                                            {filteredAllPackages?.map((data, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {data.barcode}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Tooltip title={data.available}>
                                                            <span style={{
                                                                display: 'block',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '150px'
                                                            }}
                                                            >{data.available}</span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    
                                                    <TableCell align="left">
                                                        
                                                        <Button variant='contained'
                                                            onClick={() => { handleAddToOrder(data) }} >Add <Add />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )}
                                    {selectedItem && activeFirstTab === 1 && (
                                        <TableBody>
                                            {filteredFinishedProducts?.map((data, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {data.barcode}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Tooltip title={data.available}>
                                                            <span style={{
                                                                display: 'block',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '150px'
                                                            }}
                                                            >{data.available}</span>
                                                        </Tooltip>
                                                    </TableCell>
                                                   
                                                    <TableCell align="left">
                                                        <Button variant='contained'
                                                            onClick={() => {
                                                                handleAddToOrder(data,
                                                                  
                                                                )
                                                            }} >Add <Add />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            <Divider sx={{ my: 3 }} />
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={handleClose} variant='outlined'>Back</Button>
                            </Box>
                        </Modal> */}
            </Scrollbar>
            {blockingOrder && (
              <Box
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right:0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  zIndex: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h5" sx={{ color: 'black' }}>
                    Processing...
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack className="svg-spinners--6-dots-rotate"></Stack>
                  </Box>
                </Stack>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: 'fit-content',
                backgroundColor: 'transparent',
                border: '1px solid #000',
                backdropFilter: 'blur(8px)',
                WebkitBackdropFilter: 'blur(8px)',
              }}
            >
              <CardHeader
                align={'center'}
                title={
                  <Stack
                    direction={'row'}
                    alignContent={'center'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Typography variant="h6">Order</Typography>
                    <Badge
                      badgeContent={cartItem?.length}
                      color="error"
                      overlap="circular"
                      sx={{
                        '.css-lbsn5-MuiBadge-badge': {
                          height: 'fit-content',
                          width: 'fit-content',
                          padding: '6px 6px',
                          top: '10%',
                          right: { xs: '3%', sm: '6%', md: '6%' },
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        fullWidth
                        sx={{
                          mx: 1,
                          position: 'relative',
                        }}
                        startIcon={<ShoppingCart />}
                      >
                        Items in cart
                      </Button>
                    </Badge>
                  </Stack>
                }
                sx={{ mb: '10px', mt: '-10px' }}
              />
              <Divider sx={{ borderColor: '#000' }} />
              <Box sx={{ height: '428px', py: '5px' }}>
                {cartItem?.length !== 0 ? (<Scrollbar>
                  {/* {cartItem?.map((orderItem, index) => ( */}
                  {[...cartItem].reverse().map((orderItem, index) => (
                    <>
                      <Stack spacing={3} sx={{ pr: 1, pl: 1, pt: 1, pb: 1 }}>
                        <Stack
                          direction="row"
                          sx={{
                            backgroundImage:
                              'linear-gradient(12deg, #000000 20%, #2B2B2B 40%, #434343 60%, #000 100%)',
                            p: 2,
                            color: 'common.white',
                            borderRadius: 1,
                            width: '100%',
                            position: 'relative',
                            overflow: 'hidden',
                            '&:before, &:after': {
                              content: '""',
                              position: 'absolute',
                              width: '20px',
                              height: '20px',
                              backgroundColor: '#fff',
                              border: '1px solid #000',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              borderRadius: '50%',
                            },
                            '&:before': {
                              left: '-10px',
                            },
                            '&:after': {
                              right: '-10px',
                            },
                          }}
                          spacing={2}
                        >
                          {/* <Box component={'img'}
                                                        sx={{ height: 90, width: 60, borderRadius: '4px',objectFit:'cover', }}
                                                        src={(orderItem.thumbnail && orderItem.thumbnail!='default.jpg') ? orderItem.thumbnail : 'https://i.gifer.com/84fx.gif'}
                                                        alt="Image"
                                                    /> */}
                          <Box sx={{ flexGrow: 0.5, width: '100%' }}>
                            <Stack
                              direction="row"
                              justifyContent={'space-between'}
                            >
                              <Stack direction={'column'}>
                                <Stack direction={'row'} spacing={2}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ mb: 0.5 }}
                                  >
                                    {orderItem.name}
                                  </Typography>
                                </Stack>
                                <Grid container direction="row" justifyContent="space-around">
  <Grid item xs={12} md={12}>
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      {/* Price */}
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          Price:
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          type="number"
          size="small"
          sx={{ mb: '4px' }}
          variant="standard"
          value={orderItem.unit_price}
          onChange={(e) => handlePriceChange(orderItem.package_id, e.target.value, orderItem.tax_rate)}
          InputProps={{
            inputProps: { min: 1 },
            sx: {
              color: 'common.white',
              width: '80px',
              paddingBottom: '4px',
              fontSize: '12px',
            },
          }}
        />
      </Grid>
    </Grid>
    
    
  </Grid>
  {/* Tax */}
  <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          Tax:
        </Typography>
      </Grid>
        
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          ${orderItem.unit_sales_tax || 0.0}
        {/* {orderItem.unit_price * 5 / 100} */}
        </Typography>
      </Grid>
    </Grid>

    {/* Amount */}
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          Amount:
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          ${parseFloat((orderItem.unit_price * orderItem.qty ) +( Number(orderItem.unit_sales_tax) * orderItem.qty)).toFixed(2) || 0.0}
        </Typography>
      </Grid>
    </Grid>
</Grid>

                                {/* <Typography sx={{ mt: 0.6, }} variant='subtitle2' align='left'>Select Coupon: </Typography> */}
                                {/* <Autocomplete
                                                                sx={{
                                                                    mt: 0.7,
                                                                    mb: 1.1,
                                                                    '& .MuiOutlinedInput-root': {
                                                                        color: 'common.white',
                                                                        width: '180px',
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#fff',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#fff',
                                                                        },
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        color: 'common.white',
                                                                    },
                                                                }}
                                                                fullWidth
                                                                size="small"
                                                                options={Coupons}
                                                                getOptionLabel={(option) => option.label}
                                                                value={selectedCoupon}
                                                                onChange={(event, newValue) => setSelectedCoupon(newValue)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        size="small"
                                                                        fullWidth
                                                                        {...params}
                                                                        placeholder="Coupon"
                                                                    />
                                                                )}
                                                            /> */}
                                {/* <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: '#d50000',
                                    fontWeight: 700,
                                  }}
                                >
                                  Only {orderItem.real_quantity - orderItem.qty}{' '}
                                  unit is Left in stock
                                </Typography> */}
                              </Stack>
                              <Stack direction="row" alignItems={'center'}>
                                
                                <Stack
                                  direction="row"
                                  justifyContent={'space-between'}
                                  sx={{ mt: 0.5 }}
                                >
                                  <Stack direction="row" alignItems={'center'}>
                                    <IconButton
                                      onClick={() =>
                                        handleDecrementItemCount(
                                          orderItem.package_id,
                                        )
                                      }
                                    >
                                      <Remove
                                        sx={{
                                          width: 20,
                                          height: 20,
                                          color: 'common.white',
                                        }}
                                      />
                                    </IconButton>
                                    {orderItem.qty}
                                    <IconButton
                                      onClick={() =>
                                        handleIncrementItemCount(
                                          orderItem.package_id,
                                          orderItem.real_quantity,
                                        )
                                      }
                                    >
                                      <Add
                                        sx={{
                                          width: 20,
                                          height: 20,
                                          color: 'common.white',
                                        }}
                                      />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                                <MIconButton
                                  onClick={() =>
                                    handleRemoveFromOrder(orderItem.package_id)
                                  }
                                >
                                  <Delete sx={{ color: '#d50000' }} />
                                </MIconButton>
                              </Stack>
                            </Stack>
                          </Box>
                        </Stack>
                      </Stack>
                    </>
                  ))}
                </Scrollbar>):<EmptyContent
                  title="Cart is empty"
                  description="Looks like you have no items in your shopping cart."
                  img="/static/illustrations/illustration_empty_cart.svg"
                />}

                {/* } */}
              </Box>

              <Divider sx={{ borderColor: '#000', mb: -1 }} />
              <Box
                align="center"
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                {/* <Box align="left" sx={{ pl: 2, pt: 2, pr: 1 }}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Medical Marijuana : </Typography>
                                            <Typography sx={{ fontSize: '14px', }}> {parseFloat(cartTotals.thc_medical).toFixed(2)}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Edibles : </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>{parseFloat(cartTotals.thc_edible).toFixed(2)}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Non-Edibles : </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>{parseFloat(cartTotals.thc_non_edible).toFixed(2)}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Total THC : </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>{parseFloat(cartTotals.thc_medical + cartTotals.thc_edible + cartTotals.thc_non_edible).toFixed(2)}</Typography>
                                        </Box>
                                    </Box> */}
                <Box sx={{ px: 3, pt: 1.2,mb:-1 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderRadius: '10px', p: '2px' }}
                  >
                    <Typography sx={{ fontSize: '13px' }}>
                      Sub total :{' '}
                    </Typography>
                    {/* <Typography sx={{ fontSize: '14px' }}>${calculateSubtotal()}</Typography> */}
                    <Typography sx={{ fontSize: '13px' }}>
                      $
                      {parseFloat(cartTotals.subtotal * currencyRate).toFixed(
                        2,
                      )}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderRadius: '10px', p: '2px' }}
                  >
                    <Typography sx={{ fontSize: '13px' }}>
                      Discount :{' '}
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                      ${' '}
                      {parseFloat(cartTotals.discount * currencyRate).toFixed(
                        2,
                      )}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderRadius: '10px', p: '2px' }}
                  >
                    <Typography sx={{ fontSize: '13px' }}>Taxes : </Typography>
                    {/* <Typography sx={{ fontSize: '14px' }}>${calculateTaxes()}</Typography> */}
                    <Typography sx={{ fontSize: '13px' }}>
                      ${parseFloat(cartTotals.tax * currencyRate).toFixed(2)}
                    </Typography>
                  </Box>
                  {/* <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px', }}>General Fee : </Typography>
                                            <Typography sx={{ fontSize: '14px', }}>${calculateGeneralfee()}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Metrc Fee : </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>${calculateMetricFee()}</Typography>
                                        </Box> */}
                </Box>
              </Box>
              <Divider sx={{ borderColor: '#000', mt: 1 }} />
              <Stack sx={{display:'flex',justifyContent:'space-between',flexDirection:{xs:'column',md:'row'},mt:1,mb:1}} alignContent={'center'}  alignItems="center" px={1}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={2}
                  sx={{width:'50%'}}
                >
                  <Typography sx={{ fontSize: '16px',color:'error.main',fontWeight:600 }}>Total : </Typography>
                  <Typography sx={{ fontSize: '16px',color:'error.main',fontWeight:600 }}>
                    $ {parseFloat(cartTotals.total * currencyRate).toFixed(2)}
                  </Typography>
                  {/* <Typography sx={{ fontSize: '20px' }}>${(
                                            parseFloat(calculateSubtotal()) +
                                            parseFloat(calculateTaxes()) +
                                            parseFloat(calculateGeneralfee()) +
                                            parseFloat(calculateMetricFee())
                                        ).toFixed(2)}</Typography> */}
                </Box>
                <Box sx={{pr:1,}}>
                <Button
                  sx={{
                    backgroundColor: 'success.dark',
                    color: 'common.white',
                   
                  }}
                  startIcon={
                    <Stack
                      className="solar--cart-3-bold-duotone"
                      sx={{ fontSize: '1.5rem' }}
                    />
                  }
                  variant="contained"
                  color="success"
                  disabled={
                  cartItem.length===0 ||  readyToPlaceOrder||!user?.permissions?.some(permission => ['Place Order'].includes(permission) )
                  }
                  // onClick={placeOrder}
                  onClick={() => {
                    placeOrder()
                    // navigate(PATH_DASHBOARD.pov.CustomerLookup)
                    // toggleFullScreen();
                  }}
                  // onClick={() => navigate(PATH_DASHBOARD.pov.PaymentProcess)}
                >
                  {`${isPickUp ? 'Pick up' : 'Place'} Order`}
                </Button>
                </Box>
                <CheckoutConfirmation
                  open={confirmDialogOpen}
                  onClose={handleDialogCancel}
                  onConfirm={handleCheckoutConfirm}
                  title="Are you sure?"
                  message="There are no products in the cart. Do you still want to proceed with the checkout?"
                  confirmText="Proceed"
                  cancelText="Cancel"
                />

                {readyToPlaceOrder && (
                  <Button
                    sx={{ mt: 2 }}
                    fullWidth
                    variant="contained"
                    onClick={placeOrder}
                  >
                    Ready For Sale & Notify
                  </Button>
                )}
              </Stack>
            </Card>
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Page>
    )
}
