import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, List, ListItem, Collapse, ListItemText, ListItemIcon, ListSubheader, Stack } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 70,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));
const ListItemIconStyle = styled(ListItemIcon)({
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
// const ListItemIconStyle = styled(ListItemIcon)({
//   width: 22,
//   height: 22,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center'
// });

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, children } = item;
  const [open, setOpen] = useState(isActiveRoot);
  const {user} = useAuth()
  const explodedPermissions = user?.permissions

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const checkChildPermissions = (permissionChecks, items) => {
    return permissionChecks.map(({ permissions, title }) => {
      const hasAnyPermission = permissions.some((perm) =>
        explodedPermissions.includes(perm)
      );

      if (hasAnyPermission) {
        const filteredItems = items.filter((item) => title === item.title)
        if (filteredItems.length > 0) {
          return filteredItems.map((item) => {
            const { title, path } = item;
            const isActiveSub = active(path);
            return (
              <ListItemStyle
                key={title}
                component={RouterLink}
                to={path}
                sx={{
                  ...(isActiveSub && activeSubStyle)
                }}
              >
                <ListItemIconStyle>
                  <Box
                    component="span"
                    sx={{                     
                      width: 4,
                      height: 4,
                      display: 'flex',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'text.disabled',
                      transition: (theme) => theme.transitions.create('transform'),
                      ...(isActiveSub && {
                        transform: 'scale(2)',
                        bgcolor: 'primary.main'
                      })
                    }}
                  />
                </ListItemIconStyle>
                <ListItemText disableTypography primary={title} />
              </ListItemStyle>
            )
          })
        };
      }
      return null;
    });
  };

  const activeRootStyle = {
    color: '#000',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha('#000', theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle onClick={handleOpen} sx={{ ...(isActiveRoot && activeRootStyle) }}>
          <ListItemIconStyle>{icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          <Box component={Icon} icon={open ? arrowIosDownwardFill : arrowIosForwardFill} sx={{ width: 16, height: 16, ml: 1 }} />
        </ListItemStyle>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {checkChildPermissions([
                {
                  permissions: ['Place Order','adjust-orders', 'Checked In Customer','Checked Out All Customer','check-in-pickup-orders' ],
                  title: 'Customer Lookup',
                },
                {
                  permissions: ['List All Orders','View Order','adjust-orders','Order Invoice','Cancel Order','Adjust Inventory','Refund Order','Void Order','Complete Order','Edit Invoice'],
                  title: 'All Orders',
                },
                {
                  permissions: ['List All Order Queue','check-in-pickup-orders'],
                  title: 'Order Queue',
                },
                {
                  permissions: ['List All Drawers Managements','Assign to Bud-tender','Edit Drawer Details','View Drawer','Close Drawer','Edit Drawer'],
                  title: 'Drawers Managements',
                },
                {
                  permissions: ['List All Pages','Edit Pages Details'],
                  title: 'Pages',
                },
                {
                  permissions: ['List All Top Deals','Edit Top Deals', 'Delete Top Deals','Inactive Top Deals'],
                  title: 'Top Deals',
                },
                {
                  permissions: ['List All Testimonials','Edit Testimonials','Delete Testimonials','Inactive Testimonials',],
                  title: 'Testimonials',
                },
                {
                  permissions: ['List All Home Sliders','Edit Home Sliders','Delete Home Sliders','Inactive Home Sliders',],
                  title: 'Home Sliders',
                },
                {
                  permissions: ['Footer Management'],
                  title: 'Footer Management',
                },
                {
                  permissions: ['Website Settings'],
                  title: 'Settings',
                },
                {
                  permissions: ['Website Management','Website Management'],
                  title: 'Customer Signup',
                },
                {
                  permissions: ['Website Settings'],
                  title: 'Customer Enquiry',
                },
                {
                  permissions: ['Add Customer','Edit Customer','Delete Customer', ],
                  title: 'Customers',
                },
                {
                  permissions: ['List All Buy One Get One Offers','Add Buy One Get One Offers','Edit Buy One Get One Offers','Delete Buy One Get One Offers'],
                  title: 'Buy One Get One Offers',
                },
                {
                  permissions: ['List All Coupons','Edit Coupons','Delete Coupons','Add Coupons'],
                  title: 'Coupons',
                },
                {
                  permissions: ['List All Customer Points','Add Customer Point','Edit Customer Point','Delete Customer Points',],
                  title: 'Customer Points',
                },
                {
                  permissions: ['List All Customer Referrals','Add Customer Referrals','Edit Customer Referrals','Delete Customer Referrals',],
                  title: 'Customer Referrals',
                },
                {
                  permissions: ['List All Customer Types','Add Customer Types','Edit Customer Types','Delete Customer Types',],
                  title: 'Customer Types',
                },
                {
                  permissions: ['List All Vendors','Add Vendors', 'Edit Vendors', 'Delete Vendor'],
                  title: 'All Vendors',
                },
                {
                  permissions: ['List All Brokers','Add Brokers', 'Edit Brokers', 'Delete Brokers','View Brokers Details'],
                  title: 'All Brokers',
                },
                {
                  permissions: ['List All Completed Orders', 'Completed Order Invoice','Competed Order Add To Account',],
                  title: 'Completed Orders',
                },
                {
                  permissions: ['List All Brokerage Account', 'Add Brokerage Account','Edit Brokerage Account','Delete Brokerage Account',],
                  title: 'All Brokerage Account',
                },
                {
                  permissions: ['List All Vendor Type','Add Vendor Type','edit Vendor Type','Delete Vendor Type',],
                  title: 'All Vendor Types',
                },
                {
                  permissions: ['List All Receive Inventory Orders',  'Add Receive Inventory Orders','Edit Receive Inventory Orders', 'Delete Receive Inventory Orders','View Receive Inventory Order Details','Receive Order','Print QR Code','Cancel Orders',],
                  title: 'Receive Inventory Orders', 
                },
                {
                  permissions: ['List All Staffs','Add Staff', 'Edit Staff', 'Delete Staff'],
                  title: 'Staff Directory',
                },
                {
                  permissions: ['List All User Roles','Add User Roles','Edit User Roles','Delete User Roles',],
                  title: 'User Roles',
                },
                {
                  permissions: ['List All Rooms','Add Rooms','Edit Rooms','Active/Inactive Rooms','Delete Rooms'],
                  title: 'Rooms',
                },
                {
                  permissions: ['List All Strain','Add Strain', 'Edit Strain', 'Delete Strain'],
                  title: 'Strains',
                },
                {
                  permissions: ['List All Brands','Add Brand','Edit Brand','Active/Inactive Brand'],
                  title: 'Brand',
                },
                {
                  permissions: ['List All Strain Type','Add Strain Type','Edit Strain Type','Delete Strain Type',],
                  title: 'Type',
                },        
                {
                  permissions: ['List Inventory','Add Product Label','Edit Product Label','Delete Product Label',],
                  title: 'Inventory',
                },
                {
                  permissions: ['Inventory Audit'],
                  title: 'Inventory Audit',
                },
                {
                  permissions: ['List Pricing Groups','Add Pricing Groups','Edit Pricing Groups','Inactive Pricing Groups',],
                  title: 'Pricing Group',
                },
                {
                  permissions: ['Create Product Category','Edit Product Category','Delete Product Category','List All Product Category'],
                  title: 'Product Category',
                },
                {
                  permissions: ['Create Tax Category','Edit Tax Category','Delete Tax Category','List All Tax Category'],
                  title: 'Tax Category',
                },
               
                {
                  permissions: ['Inventory Reports'],
                  title: 'Inventory Reports',
                },
              ], children)}
            {/* {children.map((child) => (
              <NavItem key={child.title} item={child} active={active} />
            ))} */}

          </List>
        </Collapse>
      </>
    );
  }

  return (
    <>
   {title === 'Dashboard' ? ( <ListItemStyle component={RouterLink} to={path} sx={{ ...(isActiveRoot && activeRootStyle) }}>
      <ListItemIconStyle>
        {/* If there is an icon, display it, otherwise display the dot */}
        {icon ? icon : <Stack className="oui--dot" sx={{ fontSize: '0.7rem' }} />}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>):<ListItemStyle component={RouterLink} to={path} sx={{ ...(isActiveRoot && activeSubStyle) }}>
      <ListItemIconStyle>
        {/* If there is an icon, display it, otherwise display the dot */}
        {icon ? icon : <Stack className="oui--dot" sx={{ fontSize: '0.7rem' }} />}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>}
    </>
  );
}


NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const {user} = useAuth();
  const explodedPermissions = user?.permissions
  // console.log("explodedPermissions",explodedPermissions);
  

  const checkPermissions = (permissionChecks, items) => {
    // console.log('hasAnyPermission---1',permissionChecks)
    return permissionChecks.map(({ permissions, title,name }) => {
      const hasAnyPermission = permissions.some((perm) =>
        explodedPermissions.includes(perm)
      );
      // console.log('hasAnyPermission---2',hasAnyPermission)
      // console.log('hasAnyPermission---3',items)
      if (hasAnyPermission) {
        return items
          .filter((item) => name.includes(item.title))
          .map((item) => (
            <NavItem key={item.title}  item={item} active={match} explodedPermissions={explodedPermissions} />
          ));
      }
      return null;
    });
  };

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
            {user.user_type !=3 &&
              items
                .filter(item => item.title == 'Dashboard')
                .map(item => (
                  <NavItem key={item.title} item={item} active={match} />
                ))
            }
            {checkPermissions([
                {
                  permissions: ['Place Order','Add Executive Admin','List All Executive Admin','Edit Executive Admin','Make Order Payment', 'Approve Customer Order','Manage Daily Deals','Manage Mix and Match Deals','List All Orders','Cancel Order','List All Order Queue','List All Drawers Managements', 'Checked In Customer ',
                    'Checked Out All Customer ','adjust-orders','check-in-pickup-orders','View Order','Order Invoice','Complete Order','Edit Invoice','Refund Order','Adjust Inventory','Close Drawer','Assign to Bud-tender','Edit Drawer Details','View Drawer','Edit Drawer','Void Order' ],
                  name: 'Point Of Sale',
                },
                {
                  permissions: ['Website Management','List All Pages','Edit Pages Details','List All Top Deals','Edit Top Deals', 'Delete Top Deals','Inactive Top Deals','List All Testimonials','Edit Testimonials','Delete Testimonials','Inactive Testimonials','Customer Signup','Customer Enquiry','List All Home Sliders','Edit Home Sliders','Delete Home Sliders','Inactive Home Sliders','Footer Management','Website Settings'],
                  name: 'Website',
                },
                {
                  permissions: ['List All Customer Points','Add Customer Point','Edit Customer Point','Delete Customer Points','Add Customer','Edit Customer','List All Customers','Receive Inventory','Delete Customer','Customer Point Setting', 'List All Buy One Get One Offers','Add Buy One Get One Offers','Edit Buy One Get One Offers','Delete Buy One Get One Offers','List All Coupons','Edit Coupons','Delete Coupons','Add Coupons','List All Customer Referrals','Add Customer Referrals','Edit Customer Referrals','Delete Customer Referrals','List All Customer Types','Add Customer Types','Edit Customer Types','Delete Customer Types',],
                  name: 'Customers Management',
                },
                {
                  permissions: ['List All Vendors','Add Vendors', 'Edit Vendors', 'Delete Vendor','List All Brokers','Add Brokers', 'Edit Brokers', 'Delete Brokers','View Brokers Details','Receive Inventory','List All Completed Orders', 'Completed Order Invoice','Competed Order Add To Account','List All Brokerage Account', 'Add Brokerage Account','Edit Brokerage Account','Delete Brokerage Account','List All Vendor Type','Add Vendor Type','edit Vendor Type','Delete Vendor Type','List All Receive Inventory Orders',  'Add Receive Inventory Orders','Edit Receive Inventory Orders', 'Delete Receive Inventory Orders','View Receive Inventory Order Details','Receive Order','Print QR Code','Cancel Orders',],
                  name: 'Wholesale Order Management',
                },
                {
                  permissions: ['List All Staffs','Add Staff', 'Edit Staff', 'Delete Staff','List All User Roles','Add User Roles','Edit User Roles','Delete User Roles',],
                  name: 'Staff Managements',
                },
                {
                  permissions: ['List All Strain','Add Strain', 'Edit Strain', 'Delete Strain','Add Strain Type','Edit Strain Type','Delete Strain Type','List Inventory','Add Product Label','Edit Product Label','Delete Product Label','List Pricing Groups','Add Pricing Groups','Edit Pricing Groups','Inactive Pricing Groups','List All Product Category','Create Product Category','Edit Product Category','Delete Product Category','Create Tax Category','Edit Tax Category','Delete Tax Category','List All Tax Category','Inventory Audit','List All Rooms','Add Rooms','Edit Rooms','Active/Inactive Rooms',
                    'Delete Rooms','List All Brands','Add Brand','Edit Brand','Active/Inactive Brand'],
                  name: 'Inventory',
                },
                {
                  permissions: ['Leaf Management','Inventory Reports',],
                  name: 'Leaf Management',
                },
                {
                  permissions: ['Generate Customer Reports','Generate Waste Reports','Generate Inventory Reports',],
                  name: 'Reports',
                },
              ], items)}
            {/* {items.map((item) => (
              <NavItem key={item.title} item={item} active={match} />
            ))} */}
          </List>
        );
      })}
    </Box>
  );
}