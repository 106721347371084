import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton, } from '@material-ui/lab';
import {
    Box,
    Card,
    Grid,
    Stack,
    TextField,
    Typography,
    Button,
    Autocomplete,
} from '@material-ui/core';
// utils
import fakeRequest from '../../../../../utils/fakeRequest';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
//
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { styled } from '@material-ui/styles';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import axios from 'axios';
import useAuth from 'src/hooks/useAuth';
// ----------------------------------------------------------------------

AddBrand.propTypes = {
    isEdit: PropTypes.bool,
    currentUser: PropTypes.object
};

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
}));

export default function AddBrand({ isEdit, currentUser, onHandleCloses }) {
    const navigate = useNavigate();
    const {user} = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const NewUserSchema = Yup.object().shape({
        name: Yup.string().required('Please enter the name')
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: currentUser?.label || '',
        },
        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                let response = '';
                if (isEdit) {
                    values.status = currentUser.status
                    response = await axios.post(`${REST_API_END_POINT}inventory/edit-brand`,{values,id:currentUser?.id, facility_id: user?.facility_id, userId:user?.id})
                }else{
                    response = await axios.post(`${REST_API_END_POINT}inventory/add-brand`,{values, facility_id: user?.facility_id, userId:user?.id})
                }
                if(response?.data?.status === 1){
                    resetForm();
                    setSubmitting(false);
                    enqueueSnackbar(response?.data?.message, { variant: 'success' });
                    window.location.reload();
                    onHandleCloses(true);
                }else{
                    enqueueSnackbar(response?.data?.message, { variant: 'error' });
                }
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                setErrors(error);
                enqueueSnackbar('Server Error', { variant: 'error' });
            }
        }
    });

    const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Stack spacing={3}>
                            <Stack>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    type='text'
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Stack>
                            <Box gap={2} sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button  variant="outlined" onClick={onHandleCloses}>
                                    Cancel
                                </Button>
                                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                    {!isEdit ? 'Add Brand' : 'Save Changes'}
                                </LoadingButton>
                            </Box>


                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}
